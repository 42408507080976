
<div class="card table-list-card">
  <div class="row col-12 card-body">
    <div class="col-11 col-md-10 mx-3">
      <div class="d-flex mt-4 align-items-center">
        <button [routerLink]="['/home']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
        <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Perfil</h2>
      </div>
    </div>
    <form [formGroup]="formProfiles">
      <div class="col-11 card my-4 mx-3">
        <div class="card-body p-4">
          <div class="col-12 mb-3">
            <label for="name">Nombre de Perfil</label>
            <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="emailHelp" placeholder="Ingresa nombre" maxlength="100">
            @if (formProfiles.get('name')?.touched && formProfiles.get('name')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un nombre.</p>
            }
          </div>
          <div class="col-12">
            <label for="description">Descripción</label>
            <textarea class="form-control" id="description" formControlName="description" rows="3" placeholder="Ingresa descripción" maxlength="50"></textarea>
            @if (formProfiles.get('description')?.touched && formProfiles.get('description')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese descripción.</p>
            }
          </div>
        </div>
      </div>
    </form>

    <div class="col-11 card my-4 mx-4">
      <h3 class="card-header">Selecciona los módulos para tu perfil</h3>
      @if (formProfiles.get('listModules')?.touched && formProfiles.get('listModules')?.errors?.['required']) {
        <p class="text-danger">Por favor, selecciona los módulos para crear el perfil.</p>
      }
      <section class="card-body p-4 row gap-3 ">
  
        @for (item of listModules; track $index) {
          <div class="card col-12 col-md-3 px-5" style="box-shadow: 0px 0px 10px #a1a1a1;">
          @if(item.idModule > 9) {
            
              <span class="example-list-section fw-bold ">
                <mat-checkbox class="example-margin"
                [checked]="item.completed"
                [color]="item.color"
                [indeterminate]="someComplete(item)"
                (change)="setAll(item,$event.checked)"
                >
                  <p class="fw-bold ">{{item.name}}</p>
                </mat-checkbox>
              </span>
              <span class="example-list-section">
                <ul>
                  @for (subtask of item.subModules; track subtask) {
                    <li class="ms-3">
                      <mat-checkbox [(ngModel)]="subtask.completed"
                                    [color]="subtask.color"
                                    (change)="updateAllComplete(item,$event.checked, subtask.idModule)">
                        {{subtask.name}}
                      </mat-checkbox>
                    </li>
                  }
                </ul>
              </span>
          
          }
       
          @else {
              <span class="example-list-section fw-bold ">
                <mat-checkbox class="example-margin"
                [checked]="item.completed"
                [color]="item.color"
                [indeterminate]="someComplete(item)"
                (change)="setAll(item,$event.checked)"
                >
                  <p class="fw-bold ">{{item.name}}</p>
                </mat-checkbox>
              </span>
              <span class="example-list-section">
                <ul>
                  @for (subtask of item.subModules; track subtask) {
                    <li class="ms-3">
                      <mat-checkbox [(ngModel)]="subtask.completed"
                                    [color]="subtask.color"
                                    (change)="updateAllComplete(item,$event.checked, subtask.idModule)">
                        {{subtask.name}}
                      </mat-checkbox>
                    </li>
                  }
                </ul>
              </span>
          }
        </div>
        }
      </section>
    </div>

    <div class="col-11 card my-4 mx-4">
      <h3 class="card-header">Seleccionar Permisos especiales</h3>
      <div class="row mb-3">
        <div  class="col-12 col-md-4">
          <mat-checkbox 
            [checked]="isEditProducts"
            color="primary"
            (change)="isEditProducts = !isEditProducts"
          >
            Crear y modificar productos
          </mat-checkbox>
        </div>
        <div  class="col-12 col-md-4">
          <mat-checkbox 
            [checked]="isEditCustomers"
            color="primary"
            (change)="isEditCustomers = !isEditCustomers"
          >
          Habilitar clientes crédito
          </mat-checkbox>
        </div>
        <div  class="col-12 col-md-4">
          <mat-checkbox 
            [checked]="isAccesBranchs"
            color="primary"
            (change)="isAccesBranchs = !isAccesBranchs"
          >
          Asignar todas las Sucursales
          </mat-checkbox>
        </div>
        <div  class="col-12 col-md-4"></div>
          <mat-checkbox 
            [checked]="isAccesUnitPrice"
            color="primary"
            (change)="isAccesUnitPrice = !isAccesUnitPrice"
          >
          Modificar Precio Unitario de Venta
          </mat-checkbox>
        </div>
    </div>

    <div class="d-flex justify-content-end p-5">
      <div class="page-btn me-3" >
        <a [routerLink]="['/home']" class="btn btn-light">
          Cancelar
        </a>
      </div>
      <div class="page-btn">
        <button type="button" class="btn btn-info" (click)="createProfile()">
          @if (title == 'Crear') {Crear Perfil }
          @else {Editar Perfil}
        </button>
      </div>
    </div>
  </div>
</div>
<p-toast>
</p-toast>




