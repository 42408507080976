import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '../_services/charts/charts.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  lineChartData: any;
  barChartData: any;
  pieChartData: any;
  doubleLineChartData: any;
  loading: boolean = true;
  receivables0to7Days: any;
  receivables8to30Days: any;
  receivablesOver30Days: any;
  receivablesOverdue: any;
  sales: any;

  constructor(private chartDataService: ChartDataService) {}

  ngOnInit() {
    this.chartDataService.getChartStats().subscribe((response) => {
      const data = response.data;
      this.lineChartData = {
        labels: data.lineChart.labels,
        datasets: [
          {
            label: 'Sales',
            backgroundColor: 'rgba(75,192,192,0.1)',
            borderColor: 'rgba(75,192,192,1)',
            data: data.lineChart.sales,
            fill: true,
          },
        ],
      };
      // total sales
      this.sales = data.lineChart.sales.reduce(
        (acc: any, curr: any) => acc + curr,
        0
      );
      this.doubleLineChartData = {
        labels: data.doubleLineChart.labels,
        datasets: [
          {
            data: data.doubleLineChart.salesTarget,
          },
          {
            data: data.doubleLineChart.sales,
          },
        ],
      };
      console.log('doubleLineChartData');
      console.log(this.doubleLineChartData);
      this.barChartData = {
        labels: data.barChart.map(
          (item: { paymentMethod: any }) => item.paymentMethod
        ),
        datasets: [
          {
            label: 'Total Sales',
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            data: data.barChart.map(
              (item: { totalSales: any }) => item.totalSales
            ),
          },
        ],
      };
      this.pieChartData = {
        labels: data.pieChart.labels,
        datasets: [
          {
            data: data.pieChart.values,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
          },
        ],
      };
      this.receivables0to7Days = data.receivables0to7Days;
      this.receivables8to30Days = data.receivables8to30Days;
      this.receivablesOver30Days = data.receivablesOver30Days;
      this.receivablesOverdue = data.receivablesOverdue;
      this.loading = false; // Data has been fetched, hide the loader
    });
  }
}
