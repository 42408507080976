import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }
  
  getSalesReport() {    
    return this.http.get<any>(`${environment.url}reports/`);
  }
  getSalesReportParam(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}reports/salesReportParam`,body,{headers: header});
  }

  getCustomersReport() {    
    return this.http.get<any>(`${environment.url}reports/reportCustomers/`);
  }

  getCustomersReportParam(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}reports/customersReportParam`,body,{headers: header});
  }

  getStockReport() {    
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}reports/StockReportParam`,null,{headers: header});
  }

  getStockReportParam() {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}reports/StockReportParam`,null,{headers: header});
  }


}