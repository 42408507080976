import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../_services/login/login.service';
import { MessageService } from 'primeng/api';
import { getCookie, setCookie } from 'typescript-cookie'
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  formLogin!: FormGroup;
  isRegister: boolean = false;
  companyName: string = ""

  constructor (
    private fb: FormBuilder,
    private route: Router,
    private loginSrv: LoginService,
    private commonSrv: CommonService,
    private messageService: MessageService,
  ) {
    this.initializeForm();
  }

  validEmail(control: any) {
    const email = control.value;
    const patronCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!patronCorreo.test(email)) {
      return { validEmail: true };
    }
    else {
      return null;
    }
  }

  passwordValidator(control: any) {
    const password = control.value;

    const containsNumbers = /\d/.test(password);
    const containsCapitalLetters = /[A-Z]/.test(password);
    const containsLowercaseLetters = /[a-z]/.test(password);
    const containsCharacters = /[^a-zA-Z0-9]/.test(password);

    if(!containsNumbers) {
      return { containsNumbers: true };
    }
    if(!containsCapitalLetters) {
      return { containsCapitalLetters: true };
    }
    if(!containsLowercaseLetters) {
      return { containsLowercaseLetters: true };
    }
    if(!containsCharacters) {
      return { containsCharacters: true };
    }
    else {
      return null
    }
  }

  initializeForm() {
    this.formLogin = this.fb.group({
      email: ['', [Validators.required, this.validEmail]],
      password: ['', [Validators.required, this.passwordValidator]]
    })
  }

  singIn() {

    if(this.formLogin.valid) {
      this.loginSrv.login(this.formLogin.value)
      .subscribe({
        next:(value) => {
          if(value.response == 'Ok') {
            if(value.data.message == 'error-user') {
              //No tiene una cuenta ¿Desea registrarse? 
              this.messageService.add({
                summary: 'Error',
                //detail: 'No tiene una cuenta ¿Desea registrarse?.',
                detail: 'Usuario inexistente o contraseña incorrecta.',
                styleClass: 'danger-background-popover',
              });
            }
            else if(value.data.message == 'blocked-account') {
              //Ha excedido el número máximo de intentos permitidos.
              this.messageService.add({
                summary: 'Error',
                detail:
                  'Ha excedido el número máximo de intentos permitidos. Favor de volver a intentar después de 5 min.',
                styleClass: 'danger-background-popover',
              });
            }
            else if(value.data.message == 'credentials-error') {
              //Usuario inexistente o contraseña incorrecta.
              this.messageService.add({
                summary: 'Error',
                detail: 'Usuario inexistente o contraseña incorrecta.',
                styleClass: 'danger-background-popover',
              });
            }
            else {
              let user1 = JSON.stringify(value.data.data);
              let user = JSON.parse(user1);

              this.commonSrv.setParameters(
                user.company,
                user.userName,
                user.branch,
                user.idSucursal,
                user.idCompany,
                user.imgPage,
                user.boxName
              );

              setCookie('userId', user.idUser, { expires: 7 });
              setCookie('companyId', user.idCompany, { expires: 7 });
              setCookie('branchId', user.idSucursal, { expires: 7 });
              setCookie('cashId', null, { expires: 7 });

              setCookie('editProducts', user.editProducts, { expires: 7 });
              setCookie('editCustomers', user.editCustomers, { expires: 7 });
              setCookie('accesBranches', user.accesBranches, { expires: 7 });
              setCookie('accessUnitPrice', user.accessUnitPrice, { expires: 7 });
              // store image
              localStorage.setItem('image', user.image);

              this.initializeForm();
              this.route.navigate(['/home']);
            }
          }
          else {
            console.warn("Error login ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al inicar sesión, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error login ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al iniciar sesión, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
    else {

    }
  }

  get company() {
    return this.companyName;
  }
}
