<section class="row col-12 mb-3">
  <div class="col-12 col-md-9">
    <div class="d-flex mt-4 align-items-center">
      <button [routerLink]="['/products']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
      <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Producto</h2>
    </div>
  </div>
  <div class="page-btn col-12 col-md-3 mt-3">
    <a class="btn btn-black w-100"><i class="bi bi-play-circle pe-2"></i>Ver video explicativo</a>
  </div>
</section>

<section>
  <form [formGroup]="formProduct" (ngSubmit)="addOrUpdateProducts()" class="row col-12">
    <div class="card card-body col-12 col-md-5 mx-2">
      <h3 class="fw-bold">Datos del producto</h3>

      <div class="col-12 form-group px-3 mb-3">
        <input class="form-check-input" type="checkbox" id="isKit" formControlName="isKit">
        <label class="form-check-label ms-2 fw-bold" for="isKit"> 
          ¿Habilitar producto como KIT?
        </label>
      </div>

      <div class="form-group px-3 mb-3">
        <label for="nameProduct">Nombre del producto*</label>
        <input type="text" class="form-control" id="nameProduct" formControlName="nameProduct" aria-describedby="emailHelp" placeholder="Ej: Jabón de mano" maxlength="100">
        @if (formProduct.get('nameProduct')?.touched && formProduct.get('nameProduct')?.errors?.['required']) {
          <p class="text-danger">Por favor, ingrese un nombre de producto.</p>
        }
      </div>

      <div class="form-group px-3 mb-3">
        <label for="category">Categoría</label>
        <mat-select id="category" class="select space-select" formControlName="category">
          <mat-option [value]=""> Seleccione o cree una nueva categoría </mat-option>
          @for (category of listCategories; track $index) {
            <mat-option [value]="category.id"> {{ category.categoryName }} </mat-option>
          }
          <mat-option [value]="" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-edit-modal"> Crear nueva categoría </mat-option>
        </mat-select>
        @if (formProduct.get('category')?.touched && formProduct.get('category')?.errors?.['required']) {
          <p class="text-danger">Por favor, seleccione una categoría.</p>
        }
      </div>

      <div class="row col-12 m-0 p-0">
        <div class="form-group mb-3 col-12 col-md-6">
          <label for="codeProduct">Código de Producto</label>
          <input type="text" class="form-control" id="codeProduct" formControlName="codeProduct" aria-describedby="emailHelp" placeholder="Ingresa un código" maxlength="50">
          @if (formProduct.get('codeProduct')?.touched && formProduct.get('codeProduct')?.errors?.['required']) {
            <p class="text-danger">Por favor, ingrese un código.</p>
          }
        </div>

        <div class="form-group px-3 mb-3 col-12 col-md-6">
          <p class="m-0">Estatus de producto</p>
          <input class="form-check-input" type="checkbox" id="status" formControlName="status">
          <label class="form-check-label ms-2" for="status"> 
            @if(formProduct.get('status')!.value) { Activo }
            @else { Inactivo }
          </label>
        </div>
      </div>

      <div class="row col-12 m-0 p-0">
        <div class="form-group mb-3 col-12 col-md-6">
          <label for="barcode">Código de barras <i class="bi bi-question-circle-fill" pInputText pTooltip="Este código corresponde el código para lectura con código de barras"></i></label>
          <input type="text" class="form-control" id="barcode" formControlName="barcode" aria-describedby="emailHelp" placeholder="Opcional" maxlength="50">
        </div>

        <div class="form-group px-3 mb-3 col-12 col-md-6">
          <label for="sku">SKU <i class="bi bi-question-circle-fill" pInputText pTooltip="Este es el código interno para control de inventario"></i></label>
          <input type="text" class="form-control" id="sku" formControlName="sku" aria-describedby="emailHelp" placeholder="Opcional" maxlength="50">
        </div>
      </div>
    </div>

    <div class="card card-body col-12 col-md-5 mx-2">
      <h3 class="fw-bold">Imágen</h3>
      <div class="col-12 d-flex flex-column justify-content-center align-items-center">
        @if (image != '' || image != null) {
            <img style="max-height: 300px;max-width: 300px;" [src]="image" alt=""> 
        }
        <div class="image-uploads" (click)="clickInput()" style="cursor: pointer;">
            <h4 class="text-info">
                <i class="feather icon-image text-info plus-down-add me-0"></i>
                @if (image == '' || image == null) { Añadir }
                @else { Cambiar }
                 imagen
            </h4>
        </div>
        <input id="inputFile" class="d-none" type="file" (change)="convertImageToBase64($event)">
          @if (formProduct.get('image')?.touched && formProduct.get('image')?.errors?.['required']) {
          <p class="text-danger">Por favor, seleccione una imagen.</p>
          }
      </div>
    </div>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Descripción</h3>
      <div class="form-group px-3 mb-3">
        <textarea class="form-control" id="description" formControlName="description" rows="3" placeholder="Agrega una descripción o información adicional de tu producto."></textarea>
        @if (formProduct.get('description')?.touched && formProduct.get('description')?.errors?.['required']) {
          <p class="text-danger">Por favor, ingrese una descripción.</p>
        }
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Unidades</h3>
      <div class="form-group px-3 mb-3 row col-12">
        <div class="col-12 col-md-4">
          <label for="salesUnit">Unidad de venta</label>
          <mat-select id="salesUnit" formControlName="salesUnit" class="select space-select" placeholder="Seleccione unidad de venta">
            @for (unit of listSalesUnit; track $index) {
              <mat-option [value]="unit.id"> {{ unit.name }} </mat-option>
            }
          </mat-select>
          @if (formProduct.get('salesUnit')?.touched && formProduct.get('salesUnit')?.errors?.['required']) {
            <p class="text-danger">Por favor, seleccione una unidad de venta.</p>
          }
        </div>
      </div>
    </section>

    @if (formProduct.get('isKit')!.value) {
      <section  class="card card-body col-12">
        <h3 class="fw-bold">Contenido del Kit</h3>
  
        <p-table [value]="productsListKit" class="table  datanew" dataKey="code" >
          <ng-template pTemplate="header">
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr style="cursor: pointer;">
              <td >{{ product.name }} </td>
              <td> <input [id]="'quantity' + product.idProduct" appDecimalRestriction appNotNegative class="form-control" type="number" placeholder="0" (change)="changeQuantityProduc(product.idProduct)" [value]="product.quantityKit" /> </td>
            </tr>
          </ng-template>
        </p-table>
  
        @if (productsListKit.length == 0) {
          <p class="text-center mt-3" style="color: gray;">Este Kit no tiene productos actualmente.</p>
        }
  
        <!-- Button trigger modal -->
        <hr style="color: #ebebeb;">
        <h3 class="" style="color: #2BA8D9; font-weight: 500;cursor: pointer;" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="bi bi-plus-lg me-2"></i> Agregar Producto</h3>
  
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="staticBackdropLabel">Productos</h2>
                <button type="button" id="btn-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                <div class="form-group row">
                  <div class="col-12 col-md-8">
                    <input type="text" class="form-control" id="searchTerm" placeholder="Filtrar por nombre, categoría, SKU o Código de barras.">
                  </div>
                  <button type="button" class="btn btn-info col-12 col-md-4" (click)="getProducts()">Filtrar productos</button>
                </div>
                <hr>
                <p-table [value]="productsData" class="table  datanew" dataKey="code" >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Nombre Producto</th>
                      <th>Costo</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr style="cursor: pointer;" (click)="selecProduct(product)">
                      <td >{{ product.name }} </td>
                      <td>{{ product.cost | currency: 'Q ' : 'code' }} </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </section>
    }



    <section class="card card-body col-12">
      <h3 class="fw-bold">Existencias</h3>

      <div class="col-12 col-md-4 form-check">
        <input class="form-check-input" type="checkbox" formControlName="isExpirationDates" id="lotes">
        <label class="form-check-label" for="lotes"> Utilizar Lotes y Caducidades </label>
      </div>
      <div class="col-12 col-md-4 form-check">
        <input class="form-check-input" type="checkbox" formControlName="allowNegativeWH" id="allow-negative-wh">
        <label class="form-check-label" for="allow-negative-wh"> Permitir Ventas en Negativos </label>
      </div>

      <div class="form-group px-3 mt-3 row col-12">
        <div class="row col-12">
          <div class="form-group col-12 col-md-6 px-3 mb-3">
            <label for="quantity">Cantidad</label>
            <!-- **appDecimalRestriction** => Directiva personalizada para solo aceptar números enteros -->
            <p-inputGroup>
              <input appDecimalRestriction appNotNegative class="inputGroup" type="number" formControlName="quantity" placeholder="0" />
              <p-inputGroupAddon>Unidades</p-inputGroupAddon>
            </p-inputGroup>
            @if (formProduct.get('quantity')?.touched && formProduct.get('quantity')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese una cantidad.</p>
            }
          </div>
          <div class="form-group col-12 col-md-6 px-3 mb-3">
            <label for="quantityMin">Cantidad mínima</label>
            <!-- **appDecimalRestriction** => Directiva personalizada para solo aceptar números enteros -->
            <p-inputGroup>
              <input appDecimalRestriction appNotNegative class="inputGroup" type="number" formControlName="quantityMin" placeholder="0" />
              <p-inputGroupAddon>Unidades</p-inputGroupAddon>
            </p-inputGroup>
            @if (formProduct.get('quantityMin')?.touched && formProduct.get('quantityMin')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese una cantidad mínima.</p>
            }
          </div>
        </div>
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Impuestos</h3>

      <div class="col-12 col-md-4 form-check">
        <input class="form-check-input" type="checkbox" id="tax" formControlName="productTax">
        <label class="form-check-label" for="tax"> Cobrar impuestos del producto </label>
      </div>

      <div class="form-group px-3 mt-3 row col-12">
        <div class="row col-12">
          <div class="form-group col-12 col-md-6 px-3 mb-3">
            <label for="iva">IVA</label>
            <mat-select id="iva" formControlName="iva" class="select space-select">
              <mat-option [value]="0.16"> 16% </mat-option>
              <mat-option [value]="0.12"> 12% </mat-option>
              <mat-option [value]="0.08"> 8% </mat-option>
              <mat-option [value]="0.00"> 0% </mat-option>
              <mat-option [value]="-1"> Exento </mat-option>
            </mat-select>
            @if (formProduct.get('iva')?.touched && formProduct.get('iva')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un nombre de Administrador.</p>
            }
          </div>
          <div class="form-group col-12 col-md-6 px-3 mb-3">
            <label for="ieps">IEPS</label>
            <mat-select id="ieps" formControlName="ieps" class="select space-select">
              <mat-option [value]="-1"> No aplica </mat-option>
              <mat-option [value]="1.6"> 160% </mat-option>
              <mat-option [value]="0.53"> 53% </mat-option>
              <mat-option [value]="0.50"> 50% </mat-option>
              <mat-option [value]="0.304"> 30.4% </mat-option>
              <mat-option [value]="0.30"> 30% </mat-option>
              <mat-option [value]="0.265"> 26.5% </mat-option>
              <mat-option [value]="0.25"> 25% </mat-option>
              <mat-option [value]="0.09"> 9% </mat-option>
              <mat-option [value]="0.08"> 8% </mat-option>
              <mat-option [value]="0.07"> 7% </mat-option>
              <mat-option [value]="0.06"> 6% </mat-option>
              <mat-option [value]="0.03"> 3% </mat-option>
              <mat-option [value]="0.00"> 0% </mat-option>
            </mat-select>
            <!-- @if (branchForm.get('adminName')?.touched && branchForm.get('adminName')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un nombre de Administrador.</p>
            } -->
          </div>
        </div>
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Variantes</h3>
      <div class="form-group px-3 mb-3 row col-12">
        <div class="col-12 col-md-4">
          <mat-select id="variants" formControlName="variants" class="select space-select" placeholder="Agregar opciones como tallas o colores">
            @for (item of listValiants; track $index) {
              <mat-option [value]="item.id"> {{ item.name }} </mat-option>
            }
          </mat-select>
          @if (formProduct.get('variants')?.touched && formProduct.get('variants')?.errors?.['required']) {
            <p class="text-danger">Por favor, seleccione una variante.</p>
          }
        </div>
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Precio de Compra</h3>
      <div class="form-group px-3 mb-3 row col-12">
        <div class="col-12 col-md-4">
          <label for="quantity">Precio de compra</label>
          <p-inputGroup>
            <p-inputGroupAddon>$</p-inputGroupAddon>
            <input type="number" class="inputGroup" formControlName="purchasePrice" placeholder="0.00" />
            <p-inputGroupAddon>
              @if (!formProduct.get('isKit')!.value) {
                por Unidad
              }
              @else { 
                por KIT
              }
            </p-inputGroupAddon>
          </p-inputGroup>
          <p class="text-muted" style="font-size: 12px;">Incluyendo impuestos</p>
          @if (formProduct.get('purchasePrice')?.touched && formProduct.get('purchasePrice')?.errors?.['required']) {
            <p class="text-danger">Por favor, ingresa un precio de compra.</p>
          }
        </div>
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Precio de Venta</h3>
      <div class="form-group px-3 mb-3 row col-12">
        <p-table [value]="listSalePrice" class="table datanew" dataKey="code" [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="header">
            <tr>
              <th>Tipo de venta</th>
              <th>Precio de venta <p class="text-muted" style="font-size: 12px;">(Con impuestos incluidos)</p> </th>
              <th>Márgen</th>
              <th>Ganancia</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td class="fw-bold">Público</td>
              <td>
                <p-inputGroup>
                  <p-inputGroupAddon>$</p-inputGroupAddon>
                  <input type="number" class="inputGroup" formControlName="salePrice" placeholder="0.00" />
                  <p-inputGroupAddon>
                    @if (!formProduct.get('isKit')!.value) {
                      por Unidad
                    }
                    @else { 
                      por KIT
                    }
                  </p-inputGroupAddon>
                </p-inputGroup>
                @if (formProduct.get('salePrice')?.touched && formProduct.get('salePrice')?.errors?.['required']) {
                  <p class="text-danger">Por favor, ingresa un precio.</p>
                }
              </td>
              <td> -- </td>
              <td> -- </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </section>

    <section class="card card-body col-12">
      <h3 class="fw-bold">Facturación</h3>
      <div class="form-group px-3 mb-3 row col-12">
        <div class="col-12 col-md-5">
          <label for="satKey">Clave SAT</label>
          <input type="text" class="form-control" id="satKey" formControlName="satKey" aria-describedby="emailHelp" placeholder="Busca por palablas clave de tu producto o ingresa una clave" maxlength="50">
          @if (formProduct.get('satKey')?.touched && formProduct.get('satKey')?.errors?.['required']) {
            <p class="text-danger">Por favor, ingresa una Clave SAT.</p>
          }
        </div>
      </div>
    </section>

    <div class="d-flex justify-content-end p-5">
      <div class="page-btn me-3" >
        <a [routerLink]="['/products']" class="btn btn-light">
          Cancelar
        </a>
      </div>
      @if(editProducts) {
        <div class="page-btn">
          <button type="submit" class="btn btn-info">
            @if (title == 'Agregar') {Crear Producto }
            @else {Editar Producto}
          </button>
        </div>
      }
    </div>
  </form>
</section>



<!-- ************************ MODAL CATEGORIA ******************************* -->
<div class="modal fade" id="add-edit-modal" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Crear nueva categoría</h4>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form [formGroup]="formCategory" (ngSubmit)="createCategory()">
                <div class="form-group px-3 mb-3">
                  <label for="categoryName">Nombre de categoría*</label>
                  <input type="text" class="form-control" id="categoryName" formControlName="categoryName" aria-describedby="emailHelp" placeholder="Nombre categoría" maxlength="50">
                  @if (formCategory.get('categoryName')?.touched && formCategory.get('categoryName')?.errors?.['required']) {
                    <p class="text-danger">Por favor, ingrese un nombre de categoría.</p>
                  }
                </div>
                <div class="form-group px-3 mb-3">
                  <label for="quantityProducts">Cantidad de productos</label>
                  <input type="text" class="form-control" id="quantityProducts" formControlName="quantityProducts" aria-describedby="emailHelp" placeholder="Cantidad de productos" maxlength="50">
                  @if (formCategory.get('quantityProducts')?.touched && formCategory.get('quantityProducts')?.errors?.['required']) {
                    <p class="text-danger">Por favor, ingrese una cantidad de productos.</p>
                  }
                </div>
              </form>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <button type="submit" (click)="createCategory()" class="btn btn btn-info">Crear</button>
            </div>
        </div>
    </div>
</div>

<p-toast>
</p-toast>
