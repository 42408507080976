import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SalesService } from '../_services/sales/sales.service';
import { MessageService } from 'primeng/api';
import { ItemsList } from '../_models/sales/itemsList';
import { tintesvalues } from '../_models/sales/tintesvalues';
import { ItemForSaleP } from '../_models/sales/itemsForSale';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '../_models/sales/customer';
import { jsPDF, jsPDFOptions } from 'jspdf';
import html2canvas from 'html2canvas';
import { currentPromotion } from '../_models/sales/currentPromotion';
import { getCookie, setCookie } from 'typescript-cookie'

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  branch: String = localStorage.getItem('idSucursal') + '';
  company: String = localStorage.getItem('company') + '';
  searchData: Array<ItemsList> = [];
  promotions: Array<currentPromotion> = [];
  itemsAdded: Array<ItemForSaleP> = [];
  nowDate: Date = new Date()
  customerList: Array<Customer> = [];
  showSearchIndicator: Boolean = true;
  showResultItems: Boolean = true;
  isValidUnitPercent: boolean = true;
  showResultCustomers: Boolean = true;
  isPercentDiscount: Boolean = false;
  allowInvoice: boolean = false;
  isAmountRestrictionCFSale: Boolean = false;
  isCouponAplied: Boolean = true;
  isCreditAllowed: Boolean = false;
  maxGifCardValue: number = 0;
  resultSeachCounter: number = 0;
  selectedCustomer!: Customer;
  subtotalPrice: number = 0;
  salePromoApliedId: number = 0;
  enableQuote: boolean = this.subtotalPrice == 0 ? true : false;
  showPdfTemplate: boolean = false;
  currency: String = '';
  customerPaymentAmount: number = 0;
  customerReturnPaymentAmount: number = 0;
  cashAmount: number = 0;
  cardAmount: number = 0;
  creditAmount: number = 0;
  transferAmount: number = 0;
  checkAmount: number = 0;
  reference: String = '';
  autorizationNo: String = '';
  discount: number = 0;
  discountPromo: number = 0;
  globalDiscountPercentAllowed: number = 0;
  percentPromo: number = 0;
  currentSaleId: number = 0;
  giftCardId!: number;
  couponCodeId!: number;
  itemForm!: FormGroup;
  discountForm!: FormGroup;
  manualItemForm!: FormGroup;
  customerForm!: FormGroup;
  detailItemForm!: FormGroup;
  templateFormDataFiles!: FormData;
  discountCouponValue: number = 0;
  quoteForm!: FormGroup;
  cfSalesForm!: FormGroup;
  quoteNo: number = 215;
  emailQuote: String = '';
  phoneNumerQuote: String = '';
  waPhoneNumerQuote: String = '';
  webSiteQuote: String = '';
  locationQuote: String = '';
  isSaleMade: boolean = false;
  currentDateNumber: number = Date.now();
  currentDate: Date = new Date(this.currentDateNumber);
  currentDateString: String = this.currentDate.toLocaleDateString();
  ticketLogo: String = '';
  customerIdentifierName: String = '';
  currentCompanyNit: String = '';
  currentUUID: String = '';
  currentCompanyAddress: String = '';
  isAccessToUnitPrice: Boolean = false;
  validateIdentifierVar: Boolean = false;
  maxCurrentCreditCustomer: number = 0;
  currentItemId: number = 0;
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closebuttonquoute') closebuttonQuote: any;
  @ViewChild('closebuttoncfsales') closebuttonCFSales: any;
  @ViewChild('closebuttonDiscount') closebuttonDiscount: any;
  @ViewChild('closebuttonManual') closebuttonManual: any;
  @ViewChild('closebuttonTinte') closebuttonTinte: any;
  @ViewChild('closebuttonCustomer') closebuttonCustomer: any;
  @ViewChild('closebuttonAddCuscomerPreview') closebuttonAddCuscomerPreview: any;
  @ViewChild('closebuttonSales') closebuttonSales: any;
  @ViewChild('searchInputItems') searchInputReference: any;
  @ViewChild('closebuttonSaleFinish') closeReadySale: any;
  @ViewChild('closebuttonUnitPercentDiscount') closebuttonUnitPercentDiscount: any;
  tinteForm!: FormGroup;

  constructor(private salesService: SalesService, private messageService: MessageService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.itemForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      salesPrice: [0, [Validators.required, Validators.min(0.01)]],
      costPrice: [0, [Validators.required, Validators.min(0.01)]],
      includeVat: [false, [Validators.required]],
      vatPercent: [, [Validators.required]],
      ieps: [, [Validators.required]]
    });
    this.discountForm = this.formBuilder.group({
      name: [''],
      discount: [0, [Validators.required, Validators.min(0.01)]]
    });
    this.quoteForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
    this.cfSalesForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      dpi: ['', []],
      passport: ['', []]
    });
    this.manualItemForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      quantity: [0, [Validators.required, Validators.min(0.01)]],
      price: [0, [Validators.required, Validators.min(0.1)]]
    });
    this.detailItemForm = this.formBuilder.group({
      price: ['', []],
      vat: ['', []],
      image: ['', []],
      name: ['', []],
      code: ['', []],
      description: ['', []],
      category: ['', []],

    });
    this.tinteForm! = this.formBuilder.group({
      tinte1:! ['', []],
      tinte2:! ['', []],
      tinte3:! ['', []],
      tinte4:! ['', []],
      tinte5:! ['', []],
      tinte6:! ['', []],
      tinte7:! ['', []],
      tinte8:! ['', []],
      tinte9:! ['', []],
      tinte10:! ['', []],
      tinte11:! ['', []],
      tinte12:! ['', []],
      tintep1:! ['', []],
      tintep2:! ['', []],
      tintep3:! ['', []],
      tintep4:! ['', []],
      tintep5:! ['', []],
      tintep6:! ['', []],
      tintep7:! ['', []],
      tintep8:! ['', []],
      tintep9:! ['', []],
      tintep10:! ['', []],
      tintep11:! ['', []],
      tintep12:! ['', []],
      comments:! ['', []]
    });


    this.customerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comments: ['',],
      businessName: ['',],
      postalCode: ['',],
      nit: [null, [Validators.required, Validators.maxLength(15)]],
      taxRegime: ['',]
    });
    this.isAccessToUnitPrice = getCookie('companyId') === 'true';
    this.getCurrency();
    this.getTicketLogo();
    this.getTemplateData();
    this.getCurrentPromotions();
    this.getCustomerIdentifierName();
    this.selectedCustomer = {
      id: 0,
      name: '',
      discount: 0,
      nit: ''
    };
  }
  getItems(searchTerm: string) {
    this.salesService.getItems(searchTerm)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            const RE = /^(?:[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?)$/
            if (result.data.length == 1 && searchTerm.length >= 13 && RE.test(searchTerm)) {
              this.showSearchIndicator = true;
              this.addItemToSale(result.data[0], false);
              this.searchInputReference.nativeElement.value = '';
            }
            else {
              this.searchData = [];
              this.searchData = result.data;
              this.resultSeachCounter = result.data.length
              this.showSearchIndicator = false;
              this.showResultItems = false;
            }
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  validateCustomerIdentifier(event: Event) {
    if (this.validateIdentifierVar) {
      const target = event.target as HTMLInputElement;
      this.salesService.validateIdentifier(target.value)
        .subscribe({
          next: result => {
            if (result.response == "Ok") {
              if (result.data != 'NIT') {
                this.customerForm.get('name')?.setValue(result.data);
              }
              else {
                this.messageService.add({
                  summary: 'Error',
                  detail: 'El identificador ingresado no es valido verifique.',
                  styleClass: 'danger-background-popover',
                });
                this.customerForm.get('nit')?.setValue('');
                this.customerForm.get('name')?.setValue('');
              }
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al realizar la operación de validación, contacte al administrador del sistema.',
                styleClass: 'danger-background-popover',
              })
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }

  validateNITsales(event: Event) {
    if (this.validateIdentifierVar) {
      const target = event.target as HTMLInputElement;
      this.salesService.validateIdentifier(target.value)
        .subscribe({
          next: result => {
            if (result.response == "Ok") {
              if (result.data != 'NIT') {
                this.selectedCustomer.id = 0;
                this.selectedCustomer.name = result.data;
                this.selectedCustomer.nit = target.value;
                this.selectedCustomer.discount = 0;
                this.selectedCustomer.email = '';
                this.selectedCustomer.phoneNumber = '';
                this.closebuttonAddCuscomerPreview.nativeElement.click();
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cliente selecionado exitosamente', styleClass: 'success-background-popover' });
                this.customerList = [];
                this.isAmountRestrictionCFSale = true;
              }
              else {
                this.messageService.add({
                  summary: 'Error',
                  detail: 'El NIT ingresado no es valido verifique.',
                  styleClass: 'danger-background-popover',
                });
              }
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al realizar la operación de validación de NIT, contacte al administrador del sistema.',
                styleClass: 'danger-background-popover',
              })
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }

  getCustomers(searchTerm: string) {
    this.salesService.getCustomers(searchTerm)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.customerList = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCustomer(searchTerm: string) {
    this.salesService.getCustomers(searchTerm)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            if (result.data.length == 1) {
              this.setCustomer(result.data[0].id, result.data[0].name, result.data[0].nit, 0, result.data[0].email, result.data[0].phoneNumber, result.data[0].creditAllowed, result.data[0].maxCredit);
            }
            else if (result.data.length == 0) {
              this.messageService.add({
                summary: 'Error',
                detail: 'La busqueda no arrojo resultados.',
                styleClass: 'danger-background-popover',
              })
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'La busqueda arrojo mas de un resultado, verifique en la busqueda de clientes.',
                styleClass: 'danger-background-popover',
              })
            }
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCustomerById(id: Number) {
    this.salesService.customerbyId(id)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.setCustomer(result.data.id, result.data.name, result.data.nit, 0, result.data.email, result.data.phoneNumber, result.data.creditAllowed, result.data.maxCredit);
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getTicketLogo() {
    this.salesService.getTicketLogo()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.ticketLogo = result.data.logoTicket;
            this.validateIdentifierVar = result.data.nValidarCliente
            this.currentCompanyNit = result.data.cNit;
            this.currentCompanyAddress = result.data.cDireccion;
            this.globalDiscountPercentAllowed = result.data.discountPercent;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCustomerIdentifierName() {
    this.salesService.getCustomerIdentifierName()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.customerIdentifierName = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getTemplateData() {
    this.salesService.getTemplateData()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.emailQuote = result.data.email;
            this.phoneNumerQuote = result.data.phoneNumber;
            this.waPhoneNumerQuote = result.data.waPhoneNumber;
            this.webSiteQuote = result.data.webSite;
            this.locationQuote = result.data.location;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getQuoteNumber() {
    this.salesService.getQuoteNumber()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.quoteNo = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  showSearchItemsIndicator(event: KeyboardEvent, isScannerKeyPress: Boolean) {
    const target = event.target as HTMLInputElement;
    if (event.key != 'Control' && event.key != 'Enter') {
      if (target.value.length >= 3) {
        this.showSearchIndicator = false;
        this.getItems(target.value)
      }
    }
  }
  showSearchItemsIndicatorEnter(event: Event, isScannerKeyPress: Boolean) {
    const target = event.target as HTMLInputElement;
    if (isScannerKeyPress == true && target.value.length >= 13) {
      this.getItems(target.value);
    }
  }
  showSearchCustomerIndicator(event: Event) {
    const target = event.target as HTMLInputElement;
    const RE = /^(?:[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?)$/
    if (target.value.length >= 7 && target.value.length <= 10 && RE.test(target.value) && this.validateIdentifierVar) {            

      this.searchOrCreateCustomerByNit(target.value, this.branch);
    }
    else {
      this.getCustomers(target.value);
    }
  }
  addOrSearchClientByNIT(event: Event) {
    const target = event.target as HTMLInputElement;
    const RE = /^(?:[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?)$/
    if (target.value.length >= 7 && target.value.length <= 10 && RE.test(target.value) && this.validateIdentifierVar) {
      this.searchOrCreateCustomerByNit(target.value, this.branch);
    }
    else if (target.value.length >= 7 && target.value.length <= 10 && RE.test(target.value) && !this.validateIdentifierVar) {
      this.getCustomer(target.value);
    }
    else {
      this.messageService.add({
        summary: 'Error',
        detail: 'El identificador ingresado no es valido, verifique.',
        styleClass: 'danger-background-popover',
      })
    }
  }
  searchOrCreateCustomerByNit(identifier: String, branchID : String) {
    this.salesService.searchOrCreateCustomerByNit(identifier, branchID)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            if (result.data != null) {
              this.getCustomerById(result.data);
              (<HTMLInputElement>document.getElementById("inputCustomerByNit")).value = "";
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'El identificador ingresado no es valido verifique.',
                styleClass: 'danger-background-popover',
              });

            }
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la operación de busqueda, contacte al administrador del sistema.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

  onSaveTinte() {  
    
    const valores: tintesvalues = this.tinteForm.value;  

    this.salesService.saveTintes(valores).subscribe(response => {
      this.tinteForm.reset();
      this.closebuttonTinte.nativeElement.click();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Bitacora de tintes registrada con exito.', styleClass: 'success-background-popover' });
    });
  }


  onCancelTinte() {
    this.closebuttonTinte.nativeElement.click();
  }

  hideSearchResult() {
    this.showSearchIndicator = true;
    this.showResultItems = true;
  }
  closeAddOrEditForm() {
    this.itemForm.reset();
    this.closebutton.nativeElement.click();
  }
  closeDiscountModal() {
    this.discountForm.reset();
    this.closebuttonDiscount.nativeElement.click();
  }
  closeManualModal() {
    this.manualItemForm.reset();
    this.closebuttonManual.nativeElement.click();
  }
  closeReadySaleModal() {
    this.closeReadySale.nativeElement.click();
  }
  closeCreateCustomerModal() {
    this.customerForm.reset();
    this.closebuttonCustomer.nativeElement.click();
  }
  closeCFSalesModal() {
    // this.cfSalesForm.reset();
    this.closebuttonCFSales.nativeElement.click();
  }
  closeSalesModal() {
    this.closebuttonSales.nativeElement.click();
  }
  closeunitPercentDiscountModal() {
    this.closebuttonUnitPercentDiscount.nativeElement.click();
  }
  closeQuote() {
    this.closebuttonQuote.nativeElement.click();
  }
  addOrUpdateItem() {
    this.itemForm.markAllAsTouched();
    if (this.itemForm.valid) {
      this.salesService.addOrUpdateItem(this.itemForm.value)
        .subscribe({
          next: data => {
            if (data.data == "Ok") {
              this.closebutton.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Producto agregado exitosamente', styleClass: 'success-background-popover' });
              this.itemForm.reset();
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al guardar el producto, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar el producto, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  deleteAllItems() {
    this.itemsAdded = [];
    this.updateSubtotal();
  }
  deleteItem(id?: number, price?: number, quantity?: number) {
    this.itemsAdded.forEach((item, index) => {

      if (item.id === id) {
        if (item.id == 0) {
          if (item.price == price && item.quantity == quantity) {
            this.itemsAdded.splice(index, 1);
          }
        }
        else {
          this.itemsAdded.splice(index, 1);
        }
      }
    });
    this.updateSubtotal();
  }
  setDiscount(isDiscount: Boolean) {
    this.isPercentDiscount = isDiscount;
  }
  applyDiscount() {
    this.discountForm.markAllAsTouched();
    if (this.discountForm.valid) {
      if (!this.isPercentDiscount) {
        this.discount = Number(this.discountForm.get('discount')?.value);
      }
      else {
        this.discount = this.subtotalPrice * (this.discountForm.get('discount')?.value / 100)
      }
      this.updateSubtotal();
      // this.discountForm.reset();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descuento aplicado con exito.', styleClass: 'success-background-popover' });
      this.closeDiscountModal();
    }
  }
  addManualItem() {
    this.manualItemForm.markAllAsTouched();
    if (this.manualItemForm.valid) {
      let itemToAdd: ItemsList = {
        cost: this.manualItemForm.get('price')?.value,
        imgUrl: '../../assets/images/cslogo.png',
        name: this.manualItemForm.get('name')?.value,
        units: this.manualItemForm.get('quantity')?.value,
        maxQuantity: 99999999,
        id: 0,
        inventoryNegative: false
      };
      this.addItemToSale(itemToAdd, true);
      this.manualItemForm.reset();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Producto añadido con exito.', styleClass: 'success-background-popover' });
      this.closeManualModal();
    }
  }
  addItemToSale(item: ItemsList, isManualItem: Boolean) {
    let result: any;
    if (!isManualItem) {
      result = this.checkPromotionAvailable(item.id!, item.cost);
    }
    else {
      result = {
        price: 0,
        quantityToPromotion: 0,
        quantityFree: 0,
        promoPrice: 0,
        promoApliedId: 0
      };
    }
    let itemToAdd = new ItemForSaleP();
    itemToAdd.id = item.id;
    itemToAdd.imgUrl = item.imgUrl;
    itemToAdd.name = item.name;
    itemToAdd.code = item.code;
    itemToAdd.price = item.cost;
    itemToAdd.quantity = 1;
    if (isManualItem) itemToAdd.quantity = item.units;
    if (item.inventoryNegative) {
      itemToAdd.maxQuantity = 9999999999;
    }
    else {
      itemToAdd.maxQuantity = item.units;
    }
    if (isManualItem) itemToAdd.maxQuantity = item.maxQuantity;
    if (result.price != 0) {
      itemToAdd.price = result.price;
      itemToAdd.discountPrice = item.cost;
    }
    else if (result.quantityFree != 0 && result.quantityToPromotion != 0) {
      itemToAdd.QuantityToPromo = result.quantityToPromotion;
      itemToAdd.freeQuantity = result.quantityFree;

    }
    else if (result.promoPrice != 0 && result.quantityToPromotion != 0) {
      itemToAdd.QuantityToPromo = result.quantityToPromotion;
      itemToAdd.promoPrice = result.promoPrice;
    }
    else {
      itemToAdd.discountPrice = 0;
    }
    if (result.promoApliedId != 0) {
      itemToAdd.promoApliedId = result.promoApliedId;
    }
    if (!isManualItem) {
      let isItemPrevAdded: boolean = false;
      this.itemsAdded.forEach(item => {
        if (item.id == itemToAdd.id) {
          const quantityToAdd: number = 1;
          let quantityResult = item.quantity! + quantityToAdd;
          if (item.maxQuantity! < quantityResult) {
            this.messageService.add({
              summary: 'Error',
              detail: 'Stock Agotado, verifique existencias.',
              styleClass: 'danger-background-popover',
            });
            isItemPrevAdded = true;
          }
          else {
            item.quantity = quantityResult;
            isItemPrevAdded = true;
          }
        }
      });
      if (!isItemPrevAdded) {
        this.itemsAdded.push(itemToAdd);
      }
    }
    else {
      this.itemsAdded.push(itemToAdd);
    }
    this.updateSubtotal();
  }
  changeUnitPrice(event: Event, id?: number) {
    const target = event.target as HTMLInputElement;
    this.itemsAdded.forEach(item => {
      if (item.id == 0) {

      }
      else {
        if (item.id == id) {
          item.price = Number(target.value);
        }
      }
    });
    this.onChangeItemQuantity(id!);
    this.updateSubtotal();
  }
  addDiscountPercent() {
    let percentDiscount: number = 0;
    if ((<HTMLInputElement>document.getElementById("unit-percent-discount-item")).value == "") {
      percentDiscount = 0;
    }
    else {
      percentDiscount = Number((<HTMLInputElement>document.getElementById("unit-percent-discount-item")).value);
    }
    if (percentDiscount <= this.globalDiscountPercentAllowed && percentDiscount != 0) {
      this.itemsAdded.forEach(item => {
        if (item.id == 0) {
        }
        else {
          if (item.id == this.currentItemId) {
            if (item.pricePrev != 0) {
              item.price = item.pricePrev! - (item.pricePrev! * percentDiscount / 100);
            }
            else {
              item.pricePrev = item.price;
              item.price = item.price! - (item.price! * percentDiscount / 100);
            }
            item.isUnitDiscountApplied = true;
            item.isUnitPercentDeleted = false;
          }
        }
      });
      this.onChangeItemQuantity(this.currentItemId);
      this.updateSubtotal();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descuento agregado exitosamente', styleClass: 'success-background-popover' });
      this.closeunitPercentDiscountModal();
    }
    else {
      this.isValidUnitPercent = false;
    }
  }
  deleteUnitPercentDiscount(id: Number) {
    this.itemsAdded.forEach(item => {
      if (item.id == 0) {
      }
      else {
        if (item.id == id) {
          if (item.pricePrev != 0) {
            item.price = item.pricePrev!;
            item.isUnitPercentDeleted = true;
            item.isUnitDiscountApplied = false;
          }
        }
      }
    });
    this.onChangeItemQuantity(this.currentItemId);
    this.updateSubtotal();
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Descuento eliminado exitosamente', styleClass: 'success-background-popover' });
  }
  addCustomer() {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      this.salesService.addCustomer(this.customerForm.value)
        .subscribe({
          next: data => {
            if (data.data.result == "Ok") {
              this.closebuttonCustomer.nativeElement.click();
              this.setCustomer(data.data.data.idCliente, data.data.data.cNombre, data.data.data.cRfc, 0, data.data.data.cEmail, data.data.data.cTelefono, false, 0)
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cliente agregado exitosamente', styleClass: 'success-background-popover' });
              this.customerForm.reset();
            }
            else if (data.data.result == 'Nit') {
              this.messageService.add({
                summary: 'Error',
                detail: 'El Nit ingresado no es valido, verifique.',
                styleClass: 'danger-background-popover',
              });
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al guardar el cliente, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar el cliente, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'Verifique que los datos de ambas pestañas esten llenas correctamente.',
        styleClass: 'danger-background-popover',
      });
    }
  }
  addSale() {
    if (this.customerPaymentAmount > 0) {
      if (this.cardAmount > 0 && (this.reference == '' || this.autorizationNo == '')) {
        this.messageService.add({
          summary: 'Error',
          detail: 'Debe llenar la referencia y número de autorización.',
          styleClass: 'danger-background-popover',
        });
      }
      else {
        if (this.customerPaymentAmount > 2500 && this.isAmountRestrictionCFSale) {
          this.showCFSalesModal();
        }
        else {
          let bodyDocument = {
            customerId: this.selectedCustomer.id,
            totalAmount: this.subtotalPrice,
            salesDetailItemList: [],
            promoId: this.salePromoApliedId,
            paymentDetailsList: [],
            couponId: this.couponCodeId,
            giftCardId: this.giftCardId,
            discountAmount: this.discountForm.get('discount')?.value,
            reference: this.reference,
            autorizationNo: this.autorizationNo,
            salesCFS: {
              name: this.cfSalesForm.get('name')?.value,
              dpi: this.cfSalesForm.get('dpi')?.value,
              passport: this.cfSalesForm.get('passport')?.value
            }
          };
          this.cfSalesForm.reset();
          let arrayItems: any = [];
          this.itemsAdded.forEach(function (element) {
            let elementToAdd = {
              idItem: element.id,
              quantity: element.quantity,
              price: element.price,
              promoApliedId: element.promoApliedId
            };
            arrayItems.push(elementToAdd);

          });
          bodyDocument.salesDetailItemList = arrayItems;
          let paysAmounts: any = [];
          let paymentToAdd = {
            paymentMethodId: 4,
            amount: this.cashAmount
          };
          paysAmounts.push(paymentToAdd);
          paymentToAdd = {
            paymentMethodId: 5,
            amount: this.cardAmount
          };
          paysAmounts.push(paymentToAdd);
          paymentToAdd = {
            paymentMethodId: 6,
            amount: this.creditAmount
          };
          paysAmounts.push(paymentToAdd);
          paymentToAdd = {
            paymentMethodId: 7,
            amount: this.transferAmount
          };
          paysAmounts.push(paymentToAdd);
          paymentToAdd = {
            paymentMethodId: 8,
            amount: this.checkAmount
          };
          paysAmounts.push(paymentToAdd);
          bodyDocument.paymentDetailsList = paysAmounts;
          this.salesService.addSale(bodyDocument)
            .subscribe({
              next: data => {
                if (data.data[1].value != 0) {
                  this.closebuttonSales.nativeElement.click();
                  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Se ha registrado la venta con folio #' + data.data[1].value, styleClass: 'success-background-popover' });
                  if (data.data[0].value) {
                    this.allowInvoice = true;
                    this.currentSaleId = data.data[1].value;
                    this.invoiceSale();
                  }
                  this.isSaleMade = true;
                  this.showFinishedSaleModal();
                }
                else {
                  this.messageService.add({
                    summary: 'Error',
                    detail: 'Ocurrio un error al realizar la venta, contacte con el administrador del sistema.',
                    styleClass: 'danger-background-popover',
                  });
                }
              }
              ,
              error: error => this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al realizar la venta, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              })
            });
        }
      }
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'Verifique los datos de venta.',
        styleClass: 'danger-background-popover',
      });
    }
  }
  updateItemQuantity(event: Event, id?: number,) {
    const target = event.target as HTMLInputElement;
    this.itemsAdded.forEach(item => {
      if (item.id == id) {
        item.quantity = Number(target.value)
      }
    });
    this.onChangeItemQuantity(id!);
    this.updateSubtotal();
  }
  updateSubtotal() {
    this.subtotalPrice = 0;
    this.itemsAdded.forEach(item => {
      this.subtotalPrice += item.total
    });
    if (this.discountPromo != 0) {
      this.subtotalPrice -= this.discount;
      this.subtotalPrice -= this.discountPromo;
    }
    else if (this.percentPromo != 0) {
      this.subtotalPrice -= this.discount;
      this.subtotalPrice -= this.subtotalPrice * (this.percentPromo / 100)
    }
    else {
      this.subtotalPrice -= this.discount;
    }
    if (this.subtotalPrice < 0) {
      this.subtotalPrice = 0;
    }
    if (this.subtotalPrice > 0) {
      this.enableQuote = false;
    }
    else {
      this.enableQuote = true;
    }
  }
  setCustomer(id?: number, name?: String, nit?: string, discount?: number, email?: String, phoneNumber?: String, isCreditAllowed?: Boolean, maxCredit?: number) {
    this.percentPromo = 0;
    this.discountPromo = 0;
    this.maxCurrentCreditCustomer = maxCredit ?? 0;
    if (id != 0) {
      this.isAmountRestrictionCFSale = false;
      this.selectedCustomer.id = id;
      this.selectedCustomer.name = name;
      this.selectedCustomer.nit = nit;
      this.selectedCustomer.discount = discount;
      this.selectedCustomer.email = email;
      this.selectedCustomer.phoneNumber = phoneNumber;
      this.closebuttonAddCuscomerPreview.nativeElement.click();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cliente selecionado exitosamente', styleClass: 'success-background-popover' });
      this.customerList = [];
      let amountToDiscount: number = 0;
      let percentToDiscount: number = 0;
      let itemsDataToProcess: ItemForSaleP[] = this.itemsAdded;
      let promoApliedinProcess: number = 0;
      const that = this;
      this.promotions.forEach(function (promo) {
        if (promo.customerId == id && promo.type == 2) {
          if (promo.rule == 1) {
            percentToDiscount = promo.itemPercent!;
          }
          else if (promo.rule == 2) {
            amountToDiscount = promo.amount!;
          }
          promoApliedinProcess = promo.id!;
        }
        else if (promo.customerId == id && promo.type == 3) {
          itemsDataToProcess.forEach(item => {
            if (item.id == promo.itemId || item.id == promo.itemFreeId) {
              if (promo.rule == 1) {
                var percent: number = ((promo.itemPercent ?? 0) / 100)
                item.price = item.price! - (percent * item.price!)
                item.promoPrice = 0;
              }
              else if (promo.rule == 2) {
                item.price = item.price! - promo.amount!;
                if (item.price < 0) {
                  item.price = 0;
                }
                item.promoPrice = 0;
              }
              else if (promo.rule == 3) {
                if (promo.itemQuantity! <= item.quantity!) {
                  let isFoundItem = false;
                  let freeItemsQuantityToApply: number = Math.floor(item.quantity! / promo.itemQuantity!);
                  freeItemsQuantityToApply = freeItemsQuantityToApply * promo.itemFreeQuantity!;
                  itemsDataToProcess.forEach(item => {
                    if (item.isFreeItem == true && item.parentItemIdFromFree == promo.itemId) {
                      isFoundItem = true;
                      item.quantity = freeItemsQuantityToApply;
                    }
                  });
                  if (!isFoundItem) {
                    that.salesService.getItem(promo.itemFreeId!)
                      .subscribe({
                        next: result => {
                          if (result.response == "Ok") {
                            let itemToAdd = new ItemForSaleP();
                            itemToAdd.id = result.data.id;
                            itemToAdd.imgUrl = result.data.imgUrl;
                            itemToAdd.name = result.data.name;
                            itemToAdd.price = 0;
                            itemToAdd.quantity = freeItemsQuantityToApply;
                            itemToAdd.isFreeItem = true;
                            itemToAdd.parentItemIdFromFree = promo.itemId;
                            itemToAdd.promoApliedId = promo.id;
                            itemsDataToProcess.push(itemToAdd);
                          }
                          else {
                            console.error(result)
                          }
                        }
                        ,
                        error: error => that.messageService.add({
                          summary: 'Error',
                          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
                          styleClass: 'danger-background-popover',
                        })
                      });
                  }
                }
              }
              else if (promo.rule == 4) {
                item.QuantityToPromo = promo.itemQuantity;
                item.promoPrice = promo.promoPriceQuantity;
              }
              item.promoApliedId = promo.id
            }
          });
        }
      });
      this.itemsAdded = itemsDataToProcess;
      this.salePromoApliedId = promoApliedinProcess;
      this.percentPromo = percentToDiscount;
      this.discountPromo = amountToDiscount;
    }
    else {
      this.selectedCustomer.id = 0;
      this.selectedCustomer.name = 'C/F';
      this.selectedCustomer.nit = 'C/F';
      this.selectedCustomer.discount = 0;
      this.selectedCustomer.email = '';
      this.selectedCustomer.phoneNumber = '';
      this.closebuttonAddCuscomerPreview.nativeElement.click();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cliente selecionado exitosamente', styleClass: 'success-background-popover' });
      this.customerList = [];
      this.isAmountRestrictionCFSale = true;
    }
    this.isCreditAllowed = isCreditAllowed ?? false;
    this.updateSubtotal();
  }
  calculateCustomerAmount() {
    this.customerPaymentAmount = this.cashAmount + this.cardAmount + this.creditAmount + this.transferAmount + this.checkAmount;
    this.customerReturnPaymentAmount = this.customerPaymentAmount - this.subtotalPrice
  }
  validateMaxCredit(event: Event) {
    const target = event.target as HTMLInputElement;
    let creditInput: number = Number.parseInt(target.value);
    if (this.maxCurrentCreditCustomer < this.creditAmount) {
      this.creditAmount = this.maxCurrentCreditCustomer;
    }
    this.calculateCustomerAmount();
  }
  showAddItemsModal() {
    document.getElementById('addeditmodal')?.click();
  }
  showDetailsItem(id: number) {
    document.getElementById('open-item-modal-btn')?.click();
    this.getItemDetail(id);
  }
  showQuoteModal() {
    if (!this.enableQuote) {
      this.getQuoteNumber();
      document.getElementById('show-modal-quote-send')?.click();
    }
  }
  showCFSalesModal() {
    document.getElementById('show-modal-cf-sale')?.click();
  }
  openUnitPercentDiscount(id: number) {
    document.getElementById('dicounts-by-item-btn')?.click();
    this.currentItemId = id;
  }
  showAddDiscountModal() {
    document.getElementById('show-modal-discount')?.click();
  }
  showPrintTicketModal() {
    document.getElementById('show-ticket-modal')?.click();
    // this.closeReadySaleModal();
  }
  showManualEntryModal() {
    document.getElementById('show-modal-manual-entry')?.click();
  }
  goToNewItemTab() {
    window.open('/new-product', "_blank");
  }
  showTinteModal() {    
    document.getElementById('show-tinte-manual-entry')?.click();
    this.tinteForm.get('tinte1')?.setValue('0');
    this.tinteForm.get('tinte2')?.setValue('0');
    this.tinteForm.get('tinte3')?.setValue('0');
    this.tinteForm.get('tinte4')?.setValue('0');
    this.tinteForm.get('tinte5')?.setValue('0');
    this.tinteForm.get('tinte6')?.setValue('0');
    this.tinteForm.get('tinte7')?.setValue('0');
    this.tinteForm.get('tinte8')?.setValue('0');
    this.tinteForm.get('tinte9')?.setValue('0');
    this.tinteForm.get('tinte10')?.setValue('0');
    this.tinteForm.get('tinte11')?.setValue('0');
    this.tinteForm.get('tinte12')?.setValue('0');
    this.tinteForm.get('tintep1')?.setValue('0');
    this.tinteForm.get('tintep2')?.setValue('0');
    this.tinteForm.get('tintep3')?.setValue('0');
    this.tinteForm.get('tintep4')?.setValue('0');
    this.tinteForm.get('tintep5')?.setValue('0');
    this.tinteForm.get('tintep6')?.setValue('0');
    this.tinteForm.get('tintep7')?.setValue('0');
    this.tinteForm.get('tintep8')?.setValue('0');
    this.tinteForm.get('tintep9')?.setValue('0');
    this.tinteForm.get('tintep10')?.setValue('0');
    this.tinteForm.get('tintep11')?.setValue('0');
    this.tinteForm.get('tintep12')?.setValue('0');
    this.tinteForm.get('comments')?.setValue('.');

  }

  showAddCustomerModal() {
    document.getElementById('show-modal-customer-add')?.click();
  }
  showFinishedSaleModal() {
    document.getElementById('show-finished-sale-modal')?.click();
  }
  sendQuouteForm() {
    this.quoteForm.markAllAsTouched();
    if (this.quoteForm.valid) {
      const documentTemplate = this.generatePDF();
      this.templateFormDataFiles = new FormData();
      let blobfile = new Blob([documentTemplate], { type: 'application/pdf' });
      this.templateFormDataFiles.append('quote' + Date.now.toString + '.pdf', blobfile);
      this.templateFormDataFiles.append("email", this.quoteForm.get('email')?.value);
      this.templateFormDataFiles.append("message", this.quoteForm.get('message')?.value);
      this.templateFormDataFiles.append("quoteNo", this.quoteNo + '');
      this.templateFormDataFiles.append("customerId", this.selectedCustomer.id + '');
      this.templateFormDataFiles.append("items", JSON.stringify(this.itemsAdded));
      this.templateFormDataFiles.append("subtotalAmount", this.subtotalPrice + '');
      this.salesService.sendQuote(this.templateFormDataFiles)
        .subscribe({
          next: data => {
            if (data.data == "Ok") {
              this.closebutton.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cotización enviada exitosamente', styleClass: 'success-background-popover' });
              this.quoteForm.reset();
              this.closeQuote();
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al enviar la cotización, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al enviar la cotización, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  sendQuouteForm2() {
    this.quoteForm.markAllAsTouched();
    if (this.quoteForm.valid) {      
      this.templateFormDataFiles = new FormData();      
      this.templateFormDataFiles.append("email", this.quoteForm.get('email')?.value);
      this.templateFormDataFiles.append("message", this.quoteForm.get('message')?.value);
      this.templateFormDataFiles.append("quoteNo", this.quoteNo + '');
      this.templateFormDataFiles.append("customerId", this.selectedCustomer.id + '');
      this.templateFormDataFiles.append("items", JSON.stringify(this.itemsAdded));
      this.templateFormDataFiles.append("subtotalAmount", this.subtotalPrice + '');
      this.salesService.sendQuote2(this.templateFormDataFiles)
        .subscribe({
          next: data => {
            if (data.result == "Ok") {
              
              const base64WithoutPrefix = data.data;
              const byteCharacters = atob(base64WithoutPrefix);
              const byteNumbers = new Array(byteCharacters.length);

              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: 'application/pdf' });
              const blobUrl = URL.createObjectURL(blob);

              const anchor = document.createElement('a');
              anchor.href = blobUrl;
              anchor.download = "Cotizacion_pos.pdf";
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
              URL.revokeObjectURL(blobUrl);
              
              
              this.quoteForm.reset();
              this.closeQuote();
              this.closebutton.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cotización generada exitosamente', styleClass: 'success-background-popover' });
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al enviar la cotización, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al enviar la cotización, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  generatePDF(): any {
    const DATA = document.getElementById('pdf-content-template')!;
    const footerElement = document.getElementById('footer-page-template')!;
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(DATA, {
      x: 0,
      y: 0,
      callback: (doc: jsPDF) => {
        for (let i = doc.getNumberOfPages(); i > 1; i--) {
          doc.deletePage(i);
        }
        doc.html(footerElement, {
          x: 0,
          y: 658,
          callback: (doc: jsPDF) => {
            return doc.output('blob');
          }
        })
      }
    });
  }
  downloadPDF(): any {
    const DATA = document.getElementById('pdf-content-template')!;
    const footerElement = document.getElementById('footer-page-template')!;
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(DATA, {
      x: 0,
      y: 0,
      callback: (doc: jsPDF) => {
        for (let i = doc.getNumberOfPages(); i > 1; i--) {
          doc.deletePage(i);
        }
        doc.html(footerElement, {
          x: 0,
          y: 658,
          callback: (doc: jsPDF) => {
            doc.save('quote-' + this.currentDateString + '.pdf');
          }
        })
      }
    });
  }
  wait(ms: number) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  sendCFSale() {
    this.cfSalesForm.markAllAsTouched();
    if (this.cfSalesForm.valid) {
      if (this.cfSalesForm.get('dpi')?.value != '' || this.cfSalesForm.get('passport')?.value != '') {
        this.isAmountRestrictionCFSale = false;
        this.closeCFSalesModal();
        this.addSale();
      }
      else {
        this.messageService.add({
          summary: 'Error',
          detail: 'Debe llenar al menos uno de los dos campos llamados No. de DPI o No. de Pasaporte.',
          styleClass: 'danger-background-popover',
        });
      }
    }
  }
  getDiscountCoupon(event: Event) {
    const target = event.target as HTMLInputElement;
    this.salesService.getDiscountCoupon(target.value)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            if (result.data == undefined) {
              this.messageService.add({
                summary: 'Atención',
                detail: 'No encontramos ningún cupon con el código ingresado.',
                styleClass: 'danger-background-popover',
              })
            }
            else {
              if (result.data.amount != 0) {
                this.discountCouponValue = result.data.amount;
              }
              else {
                this.discountCouponValue = this.subtotalPrice * result.data.percent;
              }
              this.isPercentDiscount = false;
              this.isCouponAplied = true;
              this.discountForm.get('discount')?.disable();
              this.discountForm.patchValue({ discount: this.discountCouponValue });
              this.couponCodeId = result.data.id;
              this.giftCardId = 0;
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cupon aplicado con exito.', styleClass: 'success-background-popover' });
            }
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getGifCard(event: Event) {
    const target = event.target as HTMLInputElement;
    this.salesService.getGifCard(target.value)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            if (result.data == undefined) {
              this.messageService.add({
                summary: 'Atención',
                detail: 'No encontramos ninguna tarjeta con el código ingresado.',
                styleClass: 'danger-background-popover',
              })
            }
            else {
              if (result.data.amount != 0) {
                this.discountCouponValue = result.data.amount;
              }
              else {
                this.discountCouponValue = this.subtotalPrice * result.data.percent;
              }
              this.giftCardId = result.data.id;
              this.couponCodeId = 0;
              this.maxGifCardValue = this.discountCouponValue;
              this.isPercentDiscount = false;
              this.isCouponAplied = false;
              this.discountForm.get('discount')?.disable();
              this.discountForm.patchValue({ discount: this.discountCouponValue });
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tarjeta de regalo aplicada con exito.', styleClass: 'success-background-popover' });
            }
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  generateDTE() {
    window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + this.currentUUID, "_blank");
  }
  invoiceSale() {
    this.salesService.generateDTE(this.currentSaleId)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currentUUID = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la generación de DTE, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  checkMaxValue(event: Event) {
    const target = event.target as HTMLInputElement;
    let giftCardBalance = Number(target.value);
    if (this.maxGifCardValue < giftCardBalance) {
      this.messageService.add({
        summary: 'Atención',
        detail: 'No debe sobrepasar el total del saldo de la tarjeta de regalo.',
        styleClass: 'danger-background-popover',
      })
      let inputSet: any = event.target as HTMLInputElement | null
      inputSet.value = this.maxGifCardValue;
    }
    else {
      this.discountForm.patchValue({ discount: target.value });
    }
  }
  getCurrentPromotions() {
    this.salesService.getCurrentPromotions()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.promotions = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  checkPromotionAvailable(itemId: number, price: number): any {
    var result: any = {
      price: 0,
      quantityToPromotion: 0,
      quantityFree: 0,
      promoPrice: 0,
      promoApliedId: 0
    };
    this.promotions.forEach(function (promo) {
      if (promo.type == 1 && (promo.itemId == itemId || promo.itemFreeId == itemId)) {
        if (promo.rule == 1) {
          var percent: number = ((promo.itemPercent ?? 0) / 100)
          result.price = price - (percent * price);
          result.promoPrice = 0;
        }
        else if (promo.rule == 2) {
          result.price = price - promo.amount!;
          if (result.price < 0) {
            result.price = 0;
          }
          result.promoPrice = 0;
        }
        else if (promo.rule == 3) {
          result.quantityFree = promo.itemFreeQuantity;
          result.quantityToPromotion = promo.itemQuantity;
          result.promoPrice = 0;
        }
        else if (promo.rule == 4) {
          result.promoPrice = promo.promoPriceQuantity;
          result.quantityToPromotion = promo.itemQuantity;
        }
        result.promoApliedId = promo.id;
      }
    });
    return result;
  }
  printTicket() {
    const printContent = document.getElementById('ticket-container-to-print');
    const WindowPrt = window.open('', '', 'left=0,top=50,width=600,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt!.document.write("<style>body { width:72mm;max-width:72mm } table { font-size: small !important} .ticket{font-size:12px !important;font-family:'Times New Roman';text-align:center;width:72mm;max-width:72mm}.ticket>table,.ticket>td,.ticket>th,.ticket>tr{border-top:1px solid #000;border-collapse:collapse}.ticket>td.description,.ticket>th.description{width:40mm;max-width:40mm}.ticket>td.quantity,.ticket>th.quantity{width:10mm;max-width:10mm;word-break:break-all}.ticket>td.price,.ticket>th.price{width:22mm;max-width:22mm;word-break:break-all}.ticket>.centered{text-align:center;align-content:center}.ticket>img{max-width:40mm;width:inherit;align-self:center}</style>");
    WindowPrt!.document.write(printContent!.outerHTML);
    WindowPrt!.document.close();
    setTimeout(() => {
      WindowPrt!.focus();
      WindowPrt!.print();
      WindowPrt!.close();
    },
      1000);
  }
  onChangeItemQuantity(itemId: number) {
    let isFoundItem: Boolean = false;
    let currentItemInformation = new ItemForSaleP();
    let currentCustomerId: number = this.selectedCustomer.id == 0 ? -1 : this.selectedCustomer.id!;
    this.promotions.forEach(promo => {
      if ((promo.itemId == itemId && promo.type == 1 && promo.rule == 3) || (promo.itemId == itemId && promo.type == 3 && promo.rule == 3 && promo.customerId == currentCustomerId)) {
        if (promo.itemFreeId != 0) {
          this.itemsAdded.forEach(item => {
            if (item.id == promo.itemId) {
              currentItemInformation = item;
            }
          });
          if (promo.itemQuantity! <= currentItemInformation.quantity!) {
            let freeItemsQuantityToApply: number = Math.floor(currentItemInformation.quantity! / promo.itemQuantity!);
            freeItemsQuantityToApply = freeItemsQuantityToApply * promo.itemFreeQuantity!;
            this.itemsAdded.forEach(item => {
              if (item.isFreeItem == true && item.parentItemIdFromFree == promo.itemId) {
                isFoundItem = true;
                item.quantity = freeItemsQuantityToApply;
              }
            });
            if (!isFoundItem) {
              this.salesService.getItem(promo.itemFreeId!)
                .subscribe({
                  next: result => {
                    if (result.response == "Ok") {
                      let itemToAdd = new ItemForSaleP();
                      itemToAdd.id = result.data.id;
                      itemToAdd.imgUrl = result.data.imgUrl;
                      itemToAdd.name = result.data.name;
                      itemToAdd.price = 0;
                      itemToAdd.quantity = freeItemsQuantityToApply;
                      itemToAdd.isFreeItem = true;
                      itemToAdd.parentItemIdFromFree = promo.itemId;
                      itemToAdd.promoApliedId = promo.id;
                      this.itemsAdded.push(itemToAdd);
                    }
                    else {
                      console.error(result)
                    }
                  }
                  ,
                  error: error => this.messageService.add({
                    summary: 'Error',
                    detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
                    styleClass: 'danger-background-popover',
                  })
                });
            }
          }
        }
      }
    });
  }
  resetSaleData() {
    this.itemsAdded = [];
    this.cashAmount = 0;
    this.cardAmount = 0;
    this.creditAmount = 0;
    this.transferAmount = 0;
    this.discount = 0;
    this.checkAmount = 0;
    this.customerPaymentAmount = 0;
    this.customerReturnPaymentAmount = 0;
    this.subtotalPrice = 0;
    this.selectedCustomer = {
      id: 0,
      name: '',
      discount: 0,
      nit: '',
      creditAllowed: false,
      email: '',
      maxCredit: 0,
      phoneNumber: ''
    };
  }
  getItemDetail(itemId: number) {
    const formatter = new Intl.NumberFormat('default', {
      style: 'currency',
      currency: 'USD',
    });
    this.salesService.getItemDetail(itemId)
      .subscribe({
        next: result => {
          this.detailItemForm.setValue({
            name: result.data.name,
            price: formatter.format(result.data.price).replace('$', this.currency + ''),
            vat: formatter.format(result.data.vat).replace('$', this.currency + ''),
            image: result.data.image,
            description: result.data.description,
            code: result.data.code,
            category: result.data.category,

          });
        },
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener el producto, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

}
