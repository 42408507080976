import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BranchesList } from 'src/app/_models/branches/branchesList';
import { CashCompanies, CashData } from 'src/app/_models/cash/cashRegisterList';
import { BranchesService } from 'src/app/_services/branches/branches.service';
import { CommonService } from 'src/app/_services/common/common.service';
import { LoginService } from 'src/app/_services/login/login.service';
import { Cookies, setCookie } from 'typescript-cookie';

@Component({
  selector: 'app-cash-modal',
  templateUrl: './cash-modal.component.html',
  styleUrls: ['./cash-modal.component.css']
})
export class CashModalComponent implements OnInit, OnDestroy {

  @Input() disable: boolean = false;
  @Output() disableEmm = new EventEmitter<boolean>();

  listCashCompanies: CashCompanies[] = [];
  branchesList: BranchesList[] = [];

  constructor(
    private common: CommonService,
    private loginSrv: LoginService,
    private branchSrv: BranchesService,
  ) { }

  ngOnInit() {
    this.getBranch();
    this.getCash();
  }

  ngOnDestroy(): void {
    this.disable = false;
  }

  get accesBranches() {
    return JSON.parse(Cookies.get('accesBranches')!.toString());
  }

  getCash() {
    this.loginSrv.getCash(parseInt(this.common.companyId)).subscribe({
      next: (value) => {
        if (value.response == 'Ok') {
          value.data.forEach((branch: any) => {
            if(this.accesBranches) {
              if (this.listCashCompanies.find((x) => x.branchId == branch.cash.idSucursal)) {

                this.listCashCompanies.find((item) => item.branchId == branch.cash.idSucursal)?.cash.push(branch.cash);
              } 
              else {
                let item: CashData[] = [];
                item.push(branch.cash);
                this.listCashCompanies.push({
                  branchId: branch.branchId,
                  branchName: branch.branchName,
                  cash: item,
                });
              }
            }
            else {
              if (this.listCashCompanies.find((x) => x.branchId == branch.cash.idSucursal) && branch.cash.idSucursal == parseInt(this.common.branchId)) {

                this.listCashCompanies.find((item) => item.branchId == branch.cash.idSucursal)?.cash.push(branch.cash);
              } 
              else {
                if(branch.cash.idSucursal == parseInt(this.common.branchId)){
                  let item: CashData[] = [];
                  item.push(branch.cash);
                  this.listCashCompanies.push({
                    branchId: branch.branchId,
                    branchName: branch.branchName,
                    cash: item,
                  });
                }
              }
            }
           
          });
        }
      },
      error: (err) => {},
    });
  }
  getBranch() {
    this.branchSrv.getBranches().subscribe({
      next: (value) => {
        if (value.response == 'Ok') {
          this.branchesList = value.data;
          //this.common.setBranch(this.branchesList.find(x => x.idSucursal == this.brancheId)!.branchName, this.brancheId)
          this.branchesList = this.branchesList.filter(
            (b) => b.companyId == parseInt(this.common.companyId)
          );

          // this.companyName = this.branchesList.find(
          //   (x) => x.idSucursal == parseInt(getCookie('branchId')!)
          // )!.businessName;
          // this.branchName = this.branchesList.find(
          //   (x) => x.idSucursal == parseInt(getCookie('branchId')!)
          // )!.branchName;
        }
      },
      error: (err) => {},
    });
  }

  setCash(cashId: number, branchId: number, cashName: string) {
    setCookie('cashId', cashId, { expires: 7 });
    setCookie('branchId', branchId, { expires: 7 });

    this.common.setBranch(this.branchesList.find((x: any) => x.idSucursal == branchId)!.branchName, branchId);
    this.common.setCashName(cashName);

    window.location.reload();
  }

  onHidden() {
    this.disable = false;
    this.disableEmm.emit(this.disable);
  }

}
