<div class="row justify-content-between pt-4">
    <div class="col-6">
        <p class="fs-4 fw-bolder">
            Cajas Registradoras
        </p>
    </div>
    <div class="col-2">
        <a class="btn btn-info" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-edit-modal"><i
            class="feather icon-plus-circle me-2"></i> Agregar Caja </a>
    </div>
</div>
<div class="row justify-content-start">
    <div class="col-8">
        <p class="fw-semibold">Configura tus cajas para llevar un control del dinero que circula en cada caja</p>
    </div>
</div>
<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive">
      <p-table [value]="cashRegisterList"
      class="table datanew"
      dataKey="id"
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} categorías"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]"

      >
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Status</th>
            <th>Company Id</th>
            <th>User Id</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cash>
          <tr>
            <td>{{ cash.idCaja}} </td>
            <td>{{ cash.cNombreCaja }} </td>
            <td>{{ cash.nEstatus == 1 ? 'Activo' : 'Inactivo' }} </td>
            <td>{{ cash.idSucursal }} </td>
            <td>{{ cash.idUsuarioCreacion }} </td>
            <td>{{ cash.dFechaCreacion }} </td>
            <td>{{ cash.dFechaModificacion }} </td>
            <td><a class="btn btn-info" (click)="getCash(cash.idCaja,cash.cNombreCaja, cash.nEstatus)"><i class="feather icon-edit"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>
<div class="modal fade" id="add-edit-modal" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Crear Caja</h4>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" id="modal-close-button"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row h-100">
                    <div class="col-12">
                        <form class="pb-2" [formGroup]="cashRegisterForm">
                            <div class="card mb-0">
                                <div class="card-body add-product pb-0">
                                    <div class="accordion-card-one accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="headingOne">
                                                <div class="accordion-button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-controls="collapseOne">
                                                    <div class="addproduct-icon">
                                                        <h5>
                                                            <i class="feather icon-info add-info text-info"></i><span>Datos
                                                                de Caja</span>
                                                        </h5>
                                                        <a href="javascript:void(0);"><i
                                                                class="feather icon-chevron-down chevron-down-add"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Nombre de Caja</label>
                                                                <input type="text" class="form-control" name="name"
                                                                    formControlName="name" />
                                                                <div *ngIf="!cashRegisterForm.get('name')?.valid && (cashRegisterForm.get('name')?.dirty ||cashRegisterForm.get('name')?.touched)"
                                                                    class="invalid-feedback d-block">
                                                                    Por favor, ingrese un nombre.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                          <div class="mb-3 add-product">
                                                            <label class="form-label">Estado</label>
                                                            <select class="form-control" name="nEstatus" formControlName="nEstatus">
                                                              <option value="1" selected>Activo</option>
                                                              <option value="0">Inactivo</option>
                                                            </select>
                                                            <div *ngIf="!cashRegisterForm.get('nEstatus')?.valid && (cashRegisterForm.get('nEstatus')?.dirty || cashRegisterForm.get('nEstatus')?.touched)" class="invalid-feedback d-block">
                                                              Por favor, seleccione un estado.
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2" (click)="closeAddOrEditForm()">Cancelar</button>
                <button type="button" (click)="addOrUpdateCash(0)" class="btn btn btn-info">Crear y Guardar</button>
            </div>
        </div>
    </div>
</div>
<p-toast>
</p-toast>
