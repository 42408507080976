import { Component, ViewChild, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { customersList } from '../_models/customers/customersList';
import { SalesService } from '../_services/sales/sales.service';
import { CustomersService } from '../_services/customers/customers.service';
import { Cookies } from 'typescript-cookie';
import { SalesTable } from '../_models/sales/salesTable';
import { CustomerSalesDetails } from '../_models/sales/CustomerSalesDetails';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.css',
  providers: [MessageService]
})
export class CustomersComponent implements OnInit {
  @ViewChild('closebuttonCustomer') closebuttonCustomer: any;
  selectedList1: dataCompany[] = [
    { value: 'Nombre' },
    { value: 'Antiguo' },
    { value: 'Nuevo' },
  ];
  public searchDataValue = '';
  public selectedValue1 = '';
  customerForm!: FormGroup;
  templateForm!: FormGroup;
  selectedCustomer!: Boolean;
  validateIdentifierVar : Boolean = false;
  selectedCustomerA!: customersList;
  templateFormDataFiles!: FormData;
  currency: String = '';
  customerIdentifierName: String = '';
  public tableData: Array<customersList> = [];
  salesList: CustomerSalesDetails[] = [];
  dateStart!: Date | null;
  dateEnd!: Date | null;
  constructor(private salesService: SalesService, private customersService: CustomersService, private salesServ: SalesService, private messageService: MessageService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.customerForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      comments: ['',],
      businessName: [''],
      postalCode: [''],
      nit: ['', [Validators.required]],
      taxRegime: [''],
      id: [],
      creditCustomer: [false],
      creditLimit: [''],
      creditDays: [''],
      balance: [''],
    });
    this.templateForm = this.formBuilder.group({
      excelDocument: [null, Validators.required]
    });

    if(!this.editCustomer) {
      this.customerForm.get('creditCustomer')?.disable();
    }
    this.getCustomerList();
    this.getCurrency();
    this.getCustomerIdentifierName();
    this.getTicketLogo();
  }
  get editCustomer() {
    return JSON.parse(Cookies.get('editCustomers')!.toString());
  }
  downloadTemplateFile() {

  }
  getCustomerIdentifierName() {
    this.salesService.getCustomerIdentifierName()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.customerIdentifierName = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCustomerList() {
    this.customersService.getCustomers(this.searchDataValue, this.selectedValue1)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.tableData = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  sortData() { }
  submitForm() { }
  onfileChange(files: FileList) {
    if (files.length > 0) {
      this.templateFormDataFiles = new FormData();
      this.templateFormDataFiles.append('chager' + Date.now.toString + '.xlsx', files[0]);
      this.submitForm();
    }
  }
  getTicketLogo() {
    this.salesService.getTicketLogo()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.validateIdentifierVar = result.data.nValidarCliente
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  limitDecimal() {
    const {creditLimit} = this.customerForm.value;
    if(creditLimit.toString().includes('.')) {
      let countDecimal = creditLimit.toString().split('.')[1].length;
      if(countDecimal > 2) {
        this.customerForm.patchValue({creditLimit: parseFloat(creditLimit.toString().split('.')[0] + "." + creditLimit.toString().split('.')[1].slice(0,2))})
      }
    }
  }
  getCustomer(customerId: number) {
    this.customersService.getCustomer(customerId)
      .subscribe({
        next: result => {
          this.customerForm.setValue({
            name: result.data.name,
            lastName: result.data.lastName,
            phoneNumber: result.data.phoneNumber,
            email: result.data.email,
            comments: result.data.comments,
            businessName: result.data.businessName,
            postalCode: result.data.postalCode,
            nit: result.data.nit,
            taxRegime: result.data.taxRegime,
            id: result.data.id,
            creditCustomer: result.data.creditCustomer,
            creditLimit: result.data.creditLimit,
            creditDays: result.data.creditDays,
            balance: result.data.creditLimit - result.data.balance
          });
          document.getElementById('addeditmodal')?.click();
          this.selectedCustomer = true;

          if(result.data.balance > 0) {
            this.customerForm.get('creditCustomer')?.disable();
          }

          if(!this.editCustomer) {
            this.customerForm.get('creditCustomer')?.disable();
            this.customerForm.get('creditLimit')?.disable();
            this.customerForm.get('creditDays')?.disable();
          }
          this.getSales(customerId);

        },
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener el cliente, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  addCustomer() {
    this.customerForm.enable();
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      this.customersService.addOrEditCustomer(this.customerForm.value)
        .subscribe({
          next: data => {
            if (data.data == "Ok") {
              this.closebuttonCustomer.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cliente agregado exitosamente', styleClass: 'success-background-popover' });
              this.customerForm.reset();
            }
            else if(data.data == "Nit"){
              this.messageService.add({
                summary: 'Error',
                detail: 'El Identificador ingresado no es valido, verifique.',
                styleClass: 'danger-background-popover',
              });
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al guardar el cliente, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar el cliente, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'Verifique que los datos de ambas pestañas esten llenas correctamente.',
        styleClass: 'danger-background-popover',
      });
    }
  }
  validateCustomerIdentifier(event: Event) {
    if (this.validateIdentifierVar) {
      const target = event.target as HTMLInputElement;
      this.salesService.validateIdentifier(target.value)
        .subscribe({
          next: result => {
            if (result.response == "Ok") {
              if (result.data != 'NIT') {
                this.customerForm.get('name')?.setValue(result.data);
              }
              else {
                this.messageService.add({
                  summary: 'Error',
                  detail: 'El identificador ingresado no es valido verifique.',
                  styleClass: 'danger-background-popover',
                });
                this.customerForm.get('nit')?.setValue('');
                this.customerForm.get('name')?.setValue('');
              }
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al realizar la operación de validación, contacte al administrador del sistema.',
                styleClass: 'danger-background-popover',
              })
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  closeCreateCustomerModal() {
    this.customerForm.reset();
    this.closebuttonCustomer.nativeElement.click();
    this.selectedCustomer = false;
  }
  resetModal(){
    document.getElementById('addeditmodal')?.click();
    this.selectedCustomer = false;
    this.customerForm.reset();
  }
  getSales(id: number){
    let url = "";

    url += (this.dateStart == undefined) ? '' : `&dateStart=${(this.dateStart!.toISOString())?.split('T')[0]}`;
    url += (this.dateEnd == undefined) ? '' : `&dateEnd=${(this.dateEnd!.toISOString())?.split('T')[0]}`;

    this.salesServ.getSalesByCustomer(url, id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.salesList = value.data.map((item: CustomerSalesDetails) => {
            let folio = item.idSale.toString().length;
            item.folio = (folio == 1) ? '000' + item.idSale : (folio == 2) ? '00' + item.idSale : (folio == 3) ? '0' + item.idSale : item.idSale.toString();

          return item
          });

        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las ventas, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las ventas, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }
}
