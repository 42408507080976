
  <div class="card table-list-card">
    <div class="card-body">
      <h2 class="fw-bold mx-3 mt-3">Sucursales</h2>
        <div class="table-top">
          <div class="search-set">
            <div class="search-input">
              <a href="javascript:void(0);" class="btn btn-searchset">
                <i class="feather icon-search feather-search"></i>
              </a>
              <div class="dataTables_filter">
                <label>
                  <input [(ngModel)]="searchDataValue" [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="searchData(searchDataValue)" type="search" class="form-control"
                    placeholder="Filtrar por nombre, teléfono o email" />
                </label>
              </div>
            </div>
          </div>
          <div class="form-sort">
              <i class="feather icon-sliders info-img"></i>
              <mat-select [(ngModel)]="selectedValue1" [ngModelOptions]="{standalone: true}"
                class="select space-select" placeholder="Ordenar">
                <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                    <mat-option [value]="data.value">
                        {{data.value}}
                    </mat-option>
                </ng-template>
              </mat-select>
          </div>
        </div>
        <!-- /Filter -->
        <div class="table-responsive">
          <p-table 
          [value]="branchesList" 
          class="table datanew" 
          dataKey="code" 
          [tableStyle]="{'min-width': '50rem'}"
          [paginator]="true"
          [rows]="10"
          [first]="first"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} sucursales"
          (onPage)="pageChange($event)"
          [rowsPerPageOptions]="[10, 25, 50]"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Id Sucursal</th>
                <th>Nombre Empresa</th>
                <th>Código Sucursal</th>
                <th>Nombre Sucursal </th>
                <th>Nombre administrador</th>
                <th>Dirección</th>
                <th>Código Postal</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Estatus</th>
                <th>Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-branch>
              <tr>
                <td>
                  <p-tableCheckbox [value]="branch.idSucursal"></p-tableCheckbox>
                </td>
                <td>{{branch.idSucursal}}</td>
                <td>{{ branch.businessName }} </td>
                <td>{{ branch.branchCode }} </td>
                <td>{{ branch.branchName }} </td>
                <td>{{ branch.adminName }} </td>
                <td>{{ branch.branchAddress }} </td>
                <td>{{ branch.branchCP }} </td>
                <td>{{ branch.branchEmail }} </td>
                <td>{{ branch.branchPhone }} </td>
                <td>
                  @if (branch.branchStatus == 1) {Activo}
                  @else {Inactivo}
                </td>
                <td><a class="btn btn-info" [routerLink]="['/update-branch/'+branch.idSucursal]"><i class="feather icon-edit"></i></a></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- <app-custom-pagination></app-custom-pagination> -->
        <div class="d-flex justify-content-end p-5">
          <div class="page-btn me-3">
            <a href="javascript:void(0);" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#edit-stores">
              <i class="feather icon-arrow-up-circle me-2"></i> Importar Sucursales 
            </a>
          </div>
          <div class="page-btn">
            <a [routerLink]="['/new-branch']" class="btn btn-info" data-bs-toggle="modal" data-bs-target="#add-stores">
              <i class="feather icon-plus-circle me-2"></i> Agregar Sucursal 
            </a>
          </div>
        </div>
    </div>
  </div>

  <p-toast>
  </p-toast>
