import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';

//
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FileSaverModule } from 'ngx-filesaver';

//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CompaniesComponent } from './companies/companies.component';
import { sharedModule } from './shared/shared.module';
import { LoaderComponent } from './loader/loader.component';
import { BranchOfficesFormComponent } from './branch-offices-form/branch-offices-form.component';
import { HeaderComponent } from './common-component/header/header.component';
import { SidebarOneComponent } from './common-component/sidebar-one/sidebar-one.component';
import { SidebarTwoComponent } from './common-component/sidebar-two/sidebar-two.component';
import { SidebarThreeComponent } from './common-component/sidebar-three/sidebar-three.component';
import { CoreComponentComponent } from './common-component/core-component/core-component.component';
import { BranchOfficeComponent } from './branch-office/branch-office.component';
import { SalesComponent } from './sales/sales.component';
import { UsersComponent } from './users/users.component';
import { UsersFormComponent } from './users-form/users-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProductsComponent } from './products/products.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { DecimalRestrictionDirective } from './common-component/decimal-restriction.directive';
import { CustomersComponent } from './customers/customers.component';
import { NotNegativeDirective } from './_directives/not-negative.directive';
import { TransfersComponent } from './transfers/transfers.component';
import { TransfersFormComponent } from './transfers-form/transfers-form.component';
import { CashClosingComponent } from './cash-closing/cash-closing.component';
import { CashRegisterComponent } from './cash-register/cash-register.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { InventoryAuditComponent } from './inventory-audit/inventory-audit.component';
import { ProfilesTableComponent } from './profiles-table/profiles-table.component';
import { AuditResumeComponent } from './audit-resume/audit-resume.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ParametersFormComponent } from './parameters-form/parameters-form.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PromotionsFormComponent } from './promotions-form/promotions-form.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportCustomersComponent } from './report-customers/report-customers.component';
import { ReportSalesdComponent } from './report-salesd/report-salesd.component';
import { ReportSaleshComponent } from './report-salesh/report-salesh.component';
import { ReportStockComponent } from './report-stock/report-stock.component';
import { ReportStockmovesComponent } from './report-stockmoves/report-stockmoves.component';
import { ReportTransfersComponent } from './report-transfers/report-transfers.component';
import { SalesListComponent } from './sales-list/sales-list.component';
import { ThermalPrinterComponent } from './thermal-printer/thermal-printer.component';
import { SalesListDetailComponent } from './sales-list-detail/sales-list-detail.component';
import { CategoryManagerComponent } from './category-manager/category-manager.component';
import { ProductLoadingComponent } from './products/productLoading/productLoading.component';
import { PurchaseUploadComponent } from './purchase-upload/purchase-upload.component';


//Prime Ng
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';

//Angular Material
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CashClosingHistoricalComponent } from './cash-closing-historical/cash-closing-historical.component';
import { UnitOfMeasureComponent } from './unit-of-measure/unit-of-measure.component';
import { ProductVariantComponent } from './product-variant/product-variant.component';
import { MaxDecimalDirective } from './_directives/maxDecimal.directive';
import { InventoryTweaksComponent } from './inventory-tweaks/inventory-tweaks.component';
import { InventoryTweaksFormComponent } from './inventory-tweaks-form/inventory-tweaks-form.component';
import { PriceLoadingComponent } from './products/price-loading/price-loading.component';
import { CashModalComponent } from './common-component/cash-modal/cash-modal.component';


@NgModule({
  declarations: [		
    AppComponent,
    LoginComponent,
    CompaniesComponent,
    CustomersComponent,
    LoaderComponent,
    BranchOfficeComponent,
    BranchOfficesFormComponent,
    HeaderComponent,
    SidebarOneComponent,
    SidebarTwoComponent,
    SidebarThreeComponent,
    CoreComponentComponent,
    SalesComponent,
    CashClosingComponent,
    CashRegisterComponent,
    InventoryAuditComponent,
    AuditResumeComponent,
    UsersComponent,
    UsersFormComponent,
    ResetPasswordComponent,
    ProductsComponent,
    ProductFormComponent,
    DecimalRestrictionDirective,
    NotNegativeDirective,
    TransfersComponent,
    TransfersFormComponent,
    ProfilesComponent,
    ProfilesTableComponent,
    PromotionsComponent,
    PromotionsFormComponent,
    ParametersComponent,
    ParametersFormComponent,
    ReportsComponent,
    ReportCustomersComponent,
    ReportSalesdComponent,
    ReportSaleshComponent,
    ReportStockComponent,
    ReportStockmovesComponent,
    ReportTransfersComponent,
    SalesListComponent,
    SalesListDetailComponent,
    CashClosingHistoricalComponent,
    CategoryManagerComponent,
    UnitOfMeasureComponent,
    ProductVariantComponent,
    ProductLoadingComponent,
    MaxDecimalDirective,
    InventoryTweaksComponent,
    InventoryTweaksFormComponent,
    PriceLoadingComponent,
    CashModalComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    sharedModule,
    NgScrollbarModule,
    FileSaverModule,
    RadioButtonModule,
    CheckboxModule,
    InputGroupModule,
    InputGroupAddonModule,
    TooltipModule,
    CalendarModule,
    InputTextareaModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    ThermalPrinterComponent,
    HomeModule,
    ReactiveFormsModule,
    DialogModule,
  ],
  exports: [DecimalRestrictionDirective, NotNegativeDirective],
  bootstrap: [AppComponent],
})
export class AppModule {}
