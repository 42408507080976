import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { Cookies } from 'typescript-cookie';
@Injectable({
  providedIn: 'root',
})
export class CashRegisterService {
  constructor(private http: HttpClient) {}
  getCashRegisters() {
    const branchId = localStorage.getItem('idSucursal');
    return this.http.get<any>(
      `${environment.url}CashRegister?branchId=${branchId}`
    );
  }
  getCashRegister(id: number) {
    return this.http.get<any>(`${environment.url}CashRegister/` + id);
  }
  getCashRegistersByBranch(branchId: number): Observable<any> {
    return this.http.get(`/api/cashRegisters?branchId=${branchId}`);
  }
  addOrEditCashRegister(body: any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    const branchId = localStorage.getItem('idSucursal');
    // get user id from cookies
    const userId = Cookies.get('userId');
    body.branchId = branchId;
    body.userId = userId;
    return this.http.post<any>(`${environment.url}CashRegister`, body, {
      headers: header,
    });
  }
}
