<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Empresas</h4>
        </div>
    </div>
    <div class="page-btn">
        <a (click)="downloadTemplateFile()" class="btn btn-dark"><i
                class="feather icon-arrow-up-circle me-2"></i>Descargar Plantilla</a>
    </div>
    <div class="page-btn">
        <a href="javascript:void(0);" class="btn btn-light" (click)="excelDocument.click()"><i
                class="feather icon-book me-2"></i> Importar Empresas </a>
    </div>
    <div class="page-btn">
        <a class="btn btn-info" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-edit-modal"><i
                class="feather icon-plus-circle me-2"></i> Agregar Empresa </a>
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="table-top">
            <div class="search-set">
                <div class="search-input">
                    <a href="javascript:void(0);" (click)="getCompaniesList()" class="btn btn-searchset"><i
                            class="feather icon-search feather-search"></i></a>
                    <div class="dataTables_filter">
                        <label>
                            <input [(ngModel)]="searchDataValue" [ngModelOptions]="{standalone: true}"
                                 type="search" class="form-control"
                                placeholder="Filtrar por nombre, teléfono o email" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-sort">
                <i class="feather icon-sliders info-img"></i>
                <mat-select [(ngModel)]="selectedValue1" (selectionChange)="sortData()" [ngModelOptions]="{standalone: true}"
                    class="select space-select" placeholder="Ordenar">
                    <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                        <mat-option [value]="data.value">
                            {{data.value}}
                        </mat-option>
                    </ng-template>
                </mat-select>
            </div>
        </div>
        <!-- /Filter -->
        <div class="table-responsive">
            <p-table [value]="tableData" selectionMode="single" [(selection)]="selectedCompany" class="table  datanew" dataKey="id" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>Nombre Empresa </th>
                        <th>Nombre Comercial</th>
                        <th>NIT</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-company>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="company"></p-tableCheckbox>
                        </td>
                        <td>{{company.name}}</td>
                        <td>{{company.businessName}} </td>
                        <td>{{company.nit}} </td>
                        <td>Activa</td>
                        <td><a class="btn btn-info" (click)="getCompany(company.id)"><i class="feather icon-edit"></i></a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <app-custom-pagination></app-custom-pagination> -->
    </div>
</div>
<div class="modal fade" id="add-edit-modal" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Datos de la Empresa</h4>
                <button type="button" #closebutton  class="btn-close" data-bs-dismiss="modal" id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row h-100">
                    <div class="col-3">
                        <div class="row h-100">
                            <div class="col-12">
                                <div class="card h-100">
                                    <div class="card-body">
                                        <select class="form-select h-100" multiple aria-label="multiple select example">
                                            <option selected>General</option>
                                            <option value="1">Sucursales y Almacenes</option>
                                            <option value="2">Usuarios</option>
                                            <option value="3">Facturación</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <form class="pb-2" [formGroup]="companyForm" >
                            <div class="card mb-6">
                                <div class="card-body add-product pb-0">
                                    <div class="accordion-card-one accordion" id="accordionExample3">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="headingThree">
                                                <div class="accordion-button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree" aria-controls="collapseThree">
                                                    <div class="addproduct-icon list">
                                                        <h5>
                                                            <i class="feather icon-image add-info text-info"></i><span>Logo</span>
                                                        </h5>
                                                        <a href="javascript:void(0);"><i class="feather icon-chevron-down chevron-down-add"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" class="accordion-collapse collapse show"
                                                aria-labelledby="headingThree" data-bs-parent="#accordionExample3">
                                                <div class="accordion-body">
                                                    <div class="row col-12">
                                                        <div class="col-12 col-md-6">
                                                            <p class="form-label">Logo Menú</p>
                                                            @if (imgLogo != '' || imgLogo != null) {
                                                                <img [src]="imgLogo" alt=""> 
                                                            }
                                                            <div class="image-uploads" (click)="onClickInputLogo('inputImgLogo')" style="cursor: pointer;">
                                                                <h4 class="text-info">
                                                                    <i class="feather icon-image text-info plus-down-add me-0"></i>
                                                                    @if (imgLogo == '' || imgLogo == null) { Añadir }
                                                                    @else { Cambiar }
                                                                     imagen
                                                                </h4>
                                                            </div>
                                                            <input id="inputImgLogo" class="d-none" type="file" (change)="onImgSelected($event, 1)">
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <p class="form-label">Logo Ticket</p>
                                                            @if (imgLogoTicket != '' || imgLogoTicket != null) {
                                                                <img [src]="imgLogoTicket" alt=""> 
                                                            }
                                                            <div class="image-uploads" (click)="onClickInputLogo('inputImgTicket')" style="cursor: pointer;">
                                                                <h4 class="text-info">
                                                                    <i class="feather icon-image text-info plus-down-add me-0"></i>
                                                                    @if (imgLogoTicket == '' || imgLogoTicket == null) { Añadir }
                                                                    @else { Cambiar }
                                                                     imagen
                                                                </h4>
                                                            </div>
                                                            <input id="inputImgTicket" class="d-none" type="file" (change)="onImgSelected($event,2)">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mb-0">
                                <div class="card-body add-product pb-0">
                                    <div class="accordion-card-one accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="headingOne">
                                                <div class="accordion-button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-controls="collapseOne">
                                                    <div class="addproduct-icon">
                                                        <h5>
                                                            <i class="feather icon-info add-info text-info"></i><span>Datos
                                                                de contacto</span>
                                                        </h5>
                                                        <a href="javascript:void(0);"><i
                                                                class="feather icon-chevron-down chevron-down-add"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Nombre de la Empresa</label>
                                                                <input type="text" class="form-control" name="name" formControlName="name" />
                                                                <div *ngIf="!companyForm.get('name')?.valid && (companyForm.get('name')?.dirty ||companyForm.get('name')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un nombre.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Nombre Comercial</label>
                                                                <input type="text" class="form-control" name="businessName" formControlName="businessName"/>
                                                                <div *ngIf="!companyForm.get('businessName')?.valid && (companyForm.get('businessName')?.dirty ||companyForm.get('businessName')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un Nombre Comercial.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Nombre Administrador</label>
                                                                <input type="text" name="adminName" formControlName="adminName" class="form-control" />
                                                                <div *ngIf="!companyForm.get('adminName')?.valid && (companyForm.get('adminName')?.dirty ||companyForm.get('adminName')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un nombre de administrador.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Razón Social</label>
                                                                <input type="text" class="form-control" name="fiscalName" formControlName="fiscalName"/>
                                                                <div *ngIf="!companyForm.get('fiscalName')?.valid && (companyForm.get('fiscalName')?.dirty ||companyForm.get('fiscalName')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese una Razón Social.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">NIT</label>
                                                                <input type="text" class="form-control" name="nit" formControlName="nit" />
                                                                <div *ngIf="!companyForm.get('nit')?.valid && (companyForm.get('nit')?.dirty ||companyForm.get('nit')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un NIT válido.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Dirección</label>
                                                                <input type="text" class="form-control" name="address" formControlName="address"/>
                                                                <div *ngIf="!companyForm.get('address')?.valid && (companyForm.get('address')?.dirty ||companyForm.get('address')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese una dirección.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Cuidad</label>
                                                                <input type="text" class="form-control" name="city" formControlName="city"/>
                                                                <div *ngIf="!companyForm.get('city')?.valid && (companyForm.get('city')?.dirty ||companyForm.get('city')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese una Ciudad.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">País</label>
                                                                <input type="text" class="form-control" name="country" formControlName="country"/>
                                                                <div *ngIf="!companyForm.get('country')?.valid && (companyForm.get('country')?.dirty ||companyForm.get('country')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un País.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Email</label>
                                                                <input type="email" class="form-control" name="email" formControlName="email"/>
                                                                <div *ngIf="!companyForm.get('email')?.valid && (companyForm.get('email')?.dirty ||companyForm.get('email')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un Email válido.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-6 col-12">
                                                            <label for="phone" class="mb-2">Phone</label>
                                                            <input type="text" id="phone" class="form-control" mask="(000) 000-0000" [showMaskTyped]="true" name="phoneNumber" formControlName="phoneNumber">
                                                            <span class="form-text text-muted">(999) 999-9999</span>
                                                            <div *ngIf="!companyForm.get('phoneNumber')?.valid && (companyForm.get('phoneNumber')?.dirty ||companyForm.get('phoneNumber')?.touched)" class="invalid-feedback d-block">
                                                                Por favor, ingrese un número de telefono válido.
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12">
                                                            <div class="mb-3 add-product">
                                                                <label class="form-label">Identificador Fiscal</label>
                                                                <input type="text" class="form-control" name="taxIdentifier" formControlName="taxIdentifier"/>
                                                                <div *ngIf="!companyForm.get('taxIdentifier')?.valid && (companyForm.get('taxIdentifier')?.dirty ||companyForm.get('taxIdentifier')?.touched)" class="invalid-feedback d-block">
                                                                    Por favor, ingrese un identificador fiscal.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6 col-12 mt-4">
                                                            <input class="form-check-input" type="checkbox" id="validateClient" formControlName="validateClient" style="width: 20px;height: 20px;">
                                                            <label class="form-label ms-2 fw-bold" for="validateClient"> 
                                                              Validar Cliente
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2" (click)="closeAddOrEditForm()">Cancelar</button>
                <button type="button" (click)="addOrUpdateCompany()" class="btn btn btn-info">Guardar</button>
            </div>
        </div>
    </div>
</div>
<div class="d-none">
    <form [formGroup]="templateForm">
        <input  type="file" accept=".xlsx," formControlName="excelDocument" name="excelDocument" (change)="onfileChange($any($event.target).files)" #excelDocument>
    </form>
</div>
<p-toast>
</p-toast>