
<div class="card table-list-card">
  <div class="row col-12 card-body">
    <div class="col-11 col-md-10 mx-3">
      <div class="d-flex mt-4 align-items-center">
        <button [routerLink]="['/branch-offices']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
        <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Sucursal</h2>
      </div>
    </div>
    <form [formGroup]="branchForm" (ngSubmit)="createOrUpdateBranch()">
      <div class="col-11 col-md-10 card my-4 mx-3">
        <h3 class="card-header">Sucursal</h3>
        <div class="card-body">
          <div class="form-group px-3 mb-3">
            <label for="nameBranch">Nombre de la Sucursal*</label>
            <input type="text" class="form-control" id="nameBranch" formControlName="branchName" aria-describedby="emailHelp" placeholder="Ingresa el nombre">
            @if (branchForm.get('branchName')?.touched && branchForm.get('branchName')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un nombre de Sucursal.</p>
            }
          </div>
          <div class="form-group px-3 mb-3">
            <label for="codeBranch">Código de la Sucursal*</label>
            <input type="text" class="form-control" id="codeBranch" formControlName="branchCode" aria-describedby="emailHelp" placeholder="Ingresa el código">
            @if (branchForm.get('branchCode')?.touched && branchForm.get('branchCode')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un código de Sucursal.</p>
            }
          </div>
          <div class="form-group px-3 mb-3">
            <label for="nitBranch">NIT*</label>
            <input type="text" class="form-control" id="nitBranch" formControlName="branchNIT" aria-describedby="emailHelp" placeholder="Ingresa NIT">
            @if (branchForm.get('branchNIT')?.touched && branchForm.get('branchNIT')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un NIT.</p>
            }
          </div>
          <div class="form-group px-3 mb-3">
            <label for="company">Empresa*</label>
            <!-- <input type="number" class="form-control"  aria-describedby="emailHelp" placeholder="Ingresa la Empresa"> -->
            <select class="form-select" aria-label="Default select example" id="company" formControlName="idCompany">
              <option value="">Seleccione una empresa</option>
              <option value="16">HW Consulting</option>
              <option value="17">EMPRESA 1</option>
              <option value="18">Consultora SA de CV</option>
            </select>
            @if (branchForm.get('idCompany')?.touched && branchForm.get('idCompany')?.errors?.['required']) {
              <p class="text-danger">Por favor, seleccione una Empresa.</p>
            }
          </div>
          <div class="form-group px-3 mb-3">
            <label for="admin">Administrador*</label>
            <input type="text" class="form-control" id="admin" formControlName="adminName" aria-describedby="emailHelp" placeholder="Ingresa Administrador">
            @if (branchForm.get('adminName')?.touched && branchForm.get('adminName')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese un nombre de Administrador.</p>
            }
          </div>
        </div>
      </div>

      <div class="col-11 col-md-10 card my-4 mx-3">
        <h3 class="card-header">Dirección</h3>
        <div class="card-body">
          <div class="form-group px-3 mb-3">
            <label for="street">Calle</label>
            <input type="text" class="form-control" id="street" formControlName="branchAddress" aria-describedby="emailHelp" placeholder="Ingresa la Calle">
            @if (branchForm.get('branchAddress')?.touched && branchForm.get('branchAddress')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese una calle.</p>
            }
          </div>
          <div class="row col-12 m-0 p-0">
            <div class="col-12 col-md-6 form-group px-3 mb-3">
              <label for="cp">Código Postal</label>
              <input type="number" class="form-control" id="cp" formControlName="branchCP" aria-describedby="emailHelp" placeholder="Ingresa Código Postal">
              @if (branchForm.get('branchCP')?.touched && branchForm.get('branchCP')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código postal.</p>
              }
            </div>
            <div class="col-12 col-md-6 form-group px-3 mb-3">
              <label for="country">País</label>
              <input type="text" class="form-control" id="country" formControlName="branchCountry" aria-describedby="emailHelp" placeholder="Ingresa País">
              @if (branchForm.get('branchCountry')?.touched && branchForm.get('branchCountry')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un país.</p>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-md-10 card my-4 mx-3">
        <h3 class="card-header">Contacto</h3>
        <div class="card-body">
          <div class="row col-12 m-0 p-0">
            <div class="col-12 col-md-6 form-group px-3 mb-3">
              <label for="email">Email de la Sucursal</label>
              <input type="email" class="form-control" id="email" formControlName="branchEmail" aria-describedby="emailHelp" placeholder="Ingresa un email">
              @if (branchForm.get('branchEmail')?.touched && branchForm.get('branchEmail')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un email.</p>
              }
            </div>
            <div class="col-12 col-md-6 form-group px-3 mb-3">
              <label for="phone">Teléfono de la Sucursal</label>
              <input type="text" class="form-control" id="phone" formControlName="branchPhone" aria-describedby="emailHelp" placeholder="Ingresa un teléfono ">
              @if (branchForm.get('branchPhone')?.touched && branchForm.get('branchPhone')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un teléfono.</p>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end p-5">
        <div class="page-btn me-3" >
          <a [routerLink]="['/branch-offices']" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#edit-stores">
            Cancelar
          </a>
        </div>
        <div class="page-btn">
          <button type="submit" class="btn btn-info">
            @if (title == 'Nueva') {Crear Sucursal }
            @else {Editar Sucursal}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<p-toast>
</p-toast>
