
<div class="card table-list-card">
  <div class="row col-12 card-body">
    <div class="col-11 col-md-10 mx-3">
      <div class="d-flex mt-4 align-items-center">
        <button [routerLink]="['/branch-offices']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
        <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Usuario a ColorShop</h2>
      </div>
    </div>
    <form [formGroup]="userForm" (ngSubmit)="createOrUpdateUser()">
      <div class="col-11 col-md-10 card my-4 mx-3">
        <h3 class="card-header">Datos de usuario</h3>
        <div class="card-body">
          <div class="row col-12  form-group px-3 mb-3">
            <div class="col-12 col-md-6">
              <label for="name">Nombre</label>
              <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="emailHelp" placeholder="Ingresa nombre" maxlength="50">
              @if (userForm.get('name')?.touched && userForm.get('name')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un nombre.</p>
              }
            </div>
            <div class="col-12 col-md-6">
              <label for="lastName">Apellido</label>
              <input type="text" class="form-control" id="lastName" formControlName="lastName" aria-describedby="emailHelp" placeholder="Ingresa apellido" maxlength="50">
              @if (userForm.get('lastName')?.touched && userForm.get('lastName')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese apellido.</p>
              }
            </div>
          </div>
          
          <div class="form-group px-3 mb-3">
            <label for="phoneNumber">Teléfono</label>
            <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" aria-describedby="emailHelp" placeholder="Ingresa teléfono" maxlength="50">
            @if (userForm.get('phoneNumber')?.touched && userForm.get('phoneNumber')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese teléfono.</p>
            }
          </div>

          <div class="form-group px-3 mb-3">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email" aria-describedby="emailHelp" placeholder="Ingresa email" maxlength="50">
            @if (userForm.get('email')?.touched && userForm.get('email')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese email.</p>
            }
            @if (userForm.get('email')?.value != '' && userForm.get('email')?.hasError('validEmail')) {
              <p class="text-danger">Por favor, ingrese un email valido.</p>
          }
          </div>

          <div class="form-control">
            <label for="sucursal">Sucursal</label>
            <select class="form-select" aria-label="Default select example" id="sucursal" formControlName="idSucursal">
              <option value="">Seleccione una sucursal</option>
              @for (item of listBranches; track $index) {
                <option [value]="item.idSucursal"> {{ item.branchName }}</option>
              }

            </select>
          </div>

          <div class="form-group px-3 mb-3">
            <label for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" formControlName="password" aria-describedby="emailHelp" placeholder="Ingresa contraseña" maxlength="50">
            @if (userForm.get('password')?.touched && userForm.get('password')?.errors?.['required']) {
              <p class="text-danger">Por favor, ingrese contraseña.</p>
            }
            @if (userForm.get('password')?.value != '' && userForm.get('password')?.errors?.['minlength']) {
              <p class="text-danger">La contraseña debe de contener al menos 6 caracteres.</p>
            }
            @if (userForm.get('password')?.value != '' && userForm.get('password')?.hasError('containsNumbers')) {
              <p class="text-danger">La contraseña debe de contener al menos un número.</p>
            }
            @if (userForm.get('password')?.value != '' && userForm.get('password')?.hasError('containsCapitalLetters')) {
              <p class="text-danger">La contraseña debe de contener al menos una letra mayúscula.</p>
            }
            @if (userForm.get('password')?.value != '' && userForm.get('password')?.hasError('containsLowercaseLetters')) {
              <p class="text-danger">La contraseña debe de contener al menos una letra minúscula.</p>
            }
            @if (userForm.get('password')?.value != '' && userForm.get('password')?.hasError('containsCharacters')) {
              <p class="text-danger">La contraseña debe contener al menos un carácter especial.</p>
            }
          </div>
          
        </div>
      </div>

      <div class="col-11 col-md-10 card my-4 mx-3">
        <h3 class="card-header">Perfil</h3>
        <div class="card-body">
          <div class="form-group px-3 mb-3 row col-12">
            <div class="col-12 col-md-4">
              <mat-select id="idProfile" formControlName="idProfile" class="select space-select" placeholder="Seleccionar perfil">
                @for (item of listProfiles; track $index) {
                  <mat-option [value]="item.id"> {{ item.name }} </mat-option>
                }
              </mat-select>
              @if (userForm.get('idProfile')?.touched && userForm.get('idProfile')?.errors?.['required']) {
                <p class="text-danger">Por favor, seleccione un perfil.</p>
              }
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end p-5">
        <div class="page-btn me-3" >
          <a [routerLink]="['/users']" class="btn btn-light">
            Cancelar
          </a>
        </div>
        <div class="page-btn">
          <button type="submit" class="btn btn-info">
            @if (title == 'Añadir') {Crear Usuario }
            @else {Editar Usuario}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<p-toast>
</p-toast>
