import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../_services/products/products.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { CategoriesService } from '../_services/categories/categories.service';
import { Category } from '../_models/category';
import { CommonSelect } from '../_models/common';
import { ProductsKit, ProductsTable } from '../_models/products';
import { CommonService } from '../_services/common/common.service';
import { Cookies } from 'typescript-cookie';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {

  title: string = "Agregar";
  formProduct!: FormGroup;
  formCategory!: FormGroup;
  image: string = "";
  listCategories: Category[] = [];
  listValiants: CommonSelect[] = [];
  listSalesUnit: CommonSelect[] = [];
  listSalePrice: any[] = [];

  //KITS
  productsListKit: ProductsKit[] = [];
  productsData: ProductsTable[] = [];
  totalCost: number = 0;
  idProduct: number | null = null;

  constructor(
    private fb: FormBuilder,
    private productsSrv: ProductsService,
    private messageService: MessageService,
    private router: Router,
    private categoriesSrv: CategoriesService,
    private commonSrv: CommonService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getCategories();
    this.getVariants();
    this.getSalesUnit();
    this.getProducts();
  }

  @Input()
  set id(idProduct: number) {
    if(idProduct == undefined) {
      this.title = "Agregar";
      this.idProduct = null;
    }
    else {
      this.title = "Editar";
      this.getProductById(idProduct);
      this.idProduct = idProduct;
    }
  }

  get editProducts() {
    return JSON.parse(Cookies.get('editProducts')!.toString());
  }

  initializeForm() {
    
    this.formProduct = this.fb.group({
      barcode: [''],
      category: ['',Validators.required],
      codeProduct: ['', Validators.required],
      description: ['', Validators.required],
      id: null,
      idBranch: this.commonSrv.branchId,
      ieps: null,
      image: [''], //Pendiente, preguntar
      isExpirationDates: [false],
      allowNegativeWH: [false],
      isKit: [false],
      iva: [0.16],
      nameProduct: ['', Validators.required],
      productsListKit: [null],
      productTax: true,
      purchasePrice: ['',Validators.required],
      quantity: [, Validators.required],
      quantityMin: [, Validators.required],
      salePrice: [''],
      salesUnit: ['', Validators.required],
      satKey: ['',Validators.required],
      sku: [''],
      status: [true],
      variants: ['', Validators.required],
    });

    this.formCategory = this.fb.group({
      categoryName: ['', Validators.required],
      quantityProducts: [0,Validators.required],
      companyId: [this.commonSrv.companyId],
      user: ['']
    });
    this.listSalePrice.push(0.01);
  }

  getCategories() {
    this.categoriesSrv.getCategories(parseInt(this.commonSrv.companyId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listCategories = value.data;
          this.listCategories = this.listCategories.sort((a,b) => {
            if(a.categoryName < b.categoryName){ return -1; }
            else {return 1; }

            return 0;
          })
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las categorías, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las categorías, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  createCategory() {
    this.formCategory.markAsTouched();

    if(this.formCategory.valid) {
      this.categoriesSrv.createCategory(this.formCategory.value) 
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            this.getCategories();
            document.getElementById('modal-close-button')?.click();
            this.formCategory.reset();
            this.showToastOk('Categoría agregada exitosamente.');
            this.getCategories();
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al crear la categoría, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al crear la categoría, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
  }

  getVariants() {
    this.productsSrv.getVariants(parseInt(this.commonSrv.companyId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listValiants = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las variantes, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las variantes, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getSalesUnit() {
    this.productsSrv.getSalesUnit(parseInt(this.commonSrv.companyId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listSalesUnit = value.data;
        }
        else{
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener el listado de unidades de venta, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener el listado de unidades de venta, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getProductById(id: number) {
    this.productsSrv.getProductById(id, parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.formProduct.patchValue(value.data);
          this.formProduct.patchValue({salesUnit: parseInt(this.formProduct.get('salesUnit')?.value)})
          this.image = this.formProduct.get('image')?.value;
          this.productsListKit = value.data.productsListKit;
          this.listSalePrice = [];
          this.listSalePrice.push(value.data.salePrice);
          
          if(!this.editProducts) {
            this.formProduct.disable();
          }
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener la información del producto, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener la información del producto, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  addOrUpdateProducts(){
    const {salesUnit, isKit, salePrice} = this.formProduct.value;
    this.formProduct.patchValue({
      salesUnit: salesUnit.toString(),
      idBranch: this.commonSrv.branchId,
      productsListKit: (isKit) ? this.productsListKit : null,
      salePrice: (salePrice != '') ? salePrice : null
    });

    this.formProduct.markAllAsTouched();
    if(this.formProduct.valid){
      this.productsSrv.addOrUpdateProducts(this.formProduct.value) 
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            window.scroll(0,0);
            if(this.title == 'Añadir') {
              this.showToastOk('Producto agregado exitosamente.');
              this.formProduct.reset();
            }
            else {
              this.router.navigate(['/products']);
              this.showToastOk('Producto editado exitosamente.');
            }
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al guardar el producto, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar el producto, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
    else {
      console.log(this.formProduct.value);
      
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de verificar.',
        styleClass: 'danger-background-popover',
      });
    }
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }


  clickInput(){
    document.getElementById('inputFile')?.click();
  }

  readFile(file: File,subscriber: Subscriber<any>) {
    let fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      subscriber.next(fileReader.result)
      subscriber.complete();
    }
    fileReader.onerror = (error) => {
      subscriber.error(error);
      subscriber.complete();
    }
  }

  convertImageToBase64($event: Event){

    let img = ($event.target as HTMLInputElement).files![0];
    let observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFile(img,subscriber)
    }) 
    observable.subscribe((d: string) => {
      this.image = d;
      this.formProduct.patchValue({image: this.image});
    });
  }


  //KITS

  getProducts() {
    let term = "";
    if(document.getElementById('searchTerm')) term = (document.getElementById('searchTerm') as HTMLInputElement).value;
    this.productsSrv.getProducts(term,'',parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.productsData = value.data.filter((p: ProductsTable) => p.isKit != true);
          if(this.idProduct != null) this.productsData = this.productsData.filter(p => p.id != this.idProduct);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  selecProduct(product: ProductsTable) {
    if(this.productsListKit.length != 0 && this.productsListKit.some(x => x.idProduct == product.id)) {
      this.messageService.add({
        summary: '',
        detail: 'El producto seleccionado ya fue anteriormente agregado al kit.',
        styleClass: 'warning-background-popover',
      });
    }
    else {
      this.productsListKit.push({
        idProduct: product.id,
        name: product.name,
        quantityKit: 1
        //quantityKit: parseInt((document.getElementById('quantity'+product.id) as HTMLInputElement).value)
      });
      
      document.getElementById('btn-close')?.click();
    }
  }

  changeQuantityProduc(idProduct: number) {

    this.productsListKit = this.productsListKit.map(product => {
      if(product.idProduct == idProduct) {
        product.quantityKit = parseInt((document.getElementById('quantity'+product.idProduct) as HTMLInputElement).value);
        return product;
      }
      else {
        return product;
      }
    });
  }
}
