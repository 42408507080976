import { Component, HostListener, Injector, OnInit, effect, signal, untracked } from '@angular/core';
import { Router } from '@angular/router';
import { Cookies, removeCookie } from 'typescript-cookie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ColorShopPos.WebApp';

  activity = signal(true);
  time: any;
  visible: boolean = false;

  constructor(
    private route: Router,
    private inject: Injector
  ) {}

  @HostListener('mousemove',['$event'])
  public mouse(btn:any) {
    this.activity.set(true);
  }

  ngOnInit(): void {
    this.detectInactivity();
  }

  detectInactivity() {
    effect(() => {
      if(this.activity()) {

        if(this.time) {
          clearTimeout(this.time);
        }

        this.time = setTimeout(() => {
          localStorage.clear();
          this.route.navigate(['/signIn']);
          if(!window.location.href.includes('/signIn')) {
            localStorage.clear();
            Cookies.remove('userId');
            Cookies.remove('branchId');
            Cookies.remove('cashId');
            Cookies.remove('companyId');
            Cookies.remove('accesBranches');
            Cookies.remove('accessUnitPrice');
            Cookies.remove('editCustomers');
            Cookies.remove('editProducts');
            this.visible = true;
          }
          
        },600000);

        untracked(() => {
          this.activity.set(false);
        })
      }
    },{injector: this.inject})
  }
}